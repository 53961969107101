// ==============================================================================
//
// LanguageSelector.js
//
// ==============================================================================

import React, { useState, useEffect, useContext } from 'react';
import './LanguageSelector.css';
import Cookies from 'js-cookie';
import { FaVolumeUp, FaMicrophone } from 'react-icons/fa';
import { LanguageContext } from '../context/LanguageContext';

const languageOptions = [
  { code: 'en-US', name: 'English', flag: 'https://flagcdn.com/w40/us.png' },
  { code: 'de-DE', name: 'Deutsch', flag: 'https://flagcdn.com/w40/de.png' },
  { code: 'fr-FR', name: 'Français', flag: 'https://flagcdn.com/w40/fr.png' },
  { code: 'es-ES', name: 'Español', flag: 'https://flagcdn.com/w40/es.png' },
  { code: 'it-IT', name: 'Italiano', flag: 'https://flagcdn.com/w40/it.png' },
  { code: 'zh-CN', name: '中文', flag: 'https://flagcdn.com/w40/cn.png' },
  { code: 'ja-JP', name: '日本語', flag: 'https://flagcdn.com/w40/jp.png' },
  { code: 'ar-SA', name: 'العربية', flag: 'https://flagcdn.com/w40/sa.png' },
];

const translations = {
  'en-US': 'Select Your Language',
  'de-DE': 'Wähle deine Sprache aus',
  'fr-FR': 'Choisissez votre langue',
  'es-ES': 'Selecciona tu idioma',
  'it-IT': 'Seleziona la tua lingua',
  'zh-CN': '选择你的语言',
  'ja-JP': '言語を選択してください',
  'ar-SA': 'اختر لغتك',
};

const LanguageSelector = ({ onLanguageChange }) => {
  // Sprache und Änderung aus dem zentralen Context beziehen
  
  const { language, changeLanguage } = useContext(LanguageContext);
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  const [isMicEnabled, setIsMicEnabled] 	= useState(false);

  useEffect(() => {
    console.log('🌍 [LanguageSelector] Sprache aktualisiert:', language);
  }, [language]);

  // Sprache wechseln – hier wird nur der Context aktualisiert (der Provider speichert den Wert in Cookies und localStorage)
  const handleLanguageChange = (code) => {
    console.log('🌍 [LanguageSelector].handleLanguageChange: Sprache selektiert:', code);
    changeLanguage(code);
    // Falls benötigt: onLanguageChange(code); (wenn diese Callback-Funktion von einem Parent erwartet wird)
  };

  useEffect(() => {
    const savedVoice = Cookies.get('voice') === 'true';
    setIsVoiceEnabled(savedVoice);
    const savedMic = Cookies.get('mic') === 'true';
    setIsMicEnabled(savedMic);
  }, []);

  const toggleVoice = () => {
    const newVoiceState = !isVoiceEnabled;
    setIsVoiceEnabled(newVoiceState);
    Cookies.set('voice', newVoiceState, { expires: 7, sameSite: 'Lax' });
  };

  const toggleMic = () => {
    const newMicState = !isMicEnabled;
    setIsMicEnabled(newMicState);
    Cookies.set('mic', newMicState, { expires: 7, sameSite: 'Lax' });
  };

  const getFlag = (code) => {
    const option = languageOptions.find((option) => option.code === code);
    return option?.flag || '';
  };

  return (
    <div className="language-selection">
      <h3>{translations[language]}</h3>
      <div className="flags-container">
        {languageOptions.map((option) => (
          <img
            key={option.code}
            src={option.flag}
            alt={option.name}
            title={option.name}
            className={`flag-icon ${language === option.code ? 'selected' : ''}`}
            onClick={() => handleLanguageChange(option.code)}
          />
        ))}
      </div>
      {/* ---------- VOICE & MIC ------------------------------------------------------- */}
      <div className="voice-mic-container">
        <button
          className={`icon-button voice-toggle ${isVoiceEnabled ? 'active' : ''}`}
          onClick={toggleVoice}
          title="Toggle Voice Mode"
        >
          <FaVolumeUp size={20} />
        </button>
        <button
          className={`icon-button mic-toggle ${isMicEnabled ? 'active' : ''}`}
          onClick={toggleMic}
          title="Toggle Mic Mode"
        >
          <FaMicrophone size={20} />
        </button>
      </div>
    </div>
  );
};

export default LanguageSelector;
