import React, { useState, useEffect, useRef, useContext } from 'react';
import './ChatInput.css';

import Avatar 						from './Avatar.js';
import { FiPower } 					from 'react-icons/fi';
import { FaVolumeUp, FaMicrophone } from 'react-icons/fa';
import Cookies 						from 'js-cookie';
import { generateSpeech } 			from '../services/AzureSpeechService';
import { audioRef } 				from '../utils/audioManager';
import LanguageSelector 			from './LanguageSelector';
import ProgressBar 					from './ProgressBar';
import API_CONFIG 					from '../config.js';
import { FaImage } 					from 'react-icons/fa'; // Hinzufügen eines Icons für den "Show Image"-Button
import AzureSpeechRecognizer 		from './speech/recognition/AzureSpeechRecognizer';
import { LanguageContext } 			from '../context/LanguageContext';
import SpeechPlayer 				from './speech/generation/SpeechPlayer';
import SpeechInterruptDetector 		from './speech/recognition/SpeechInterruptDetector';


// Globale Variable für die erste Nachricht
let isGlobalFirstMessageSent = false;
let isGlobalFirstMessageSent2 = false;

const MAX_IMAGES = 10; // Maximale Anzahl gespeicherter Bilder

const translations = {
	'en-US': { placeholder: 'Write a message...', flag: '🇺🇸', greeting: 'Hi' },
	'de-DE': { placeholder: 'Schreibe eine Nachricht...', flag: '🇩🇪', greeting: 'Hallo' },
	'fr-FR': { placeholder: 'Écrivez un message...', flag: '🇫🇷', greeting: 'Salut' },
	'es-ES': { placeholder: 'Escribe un mensaje...', flag: '🇪🇸', greeting: 'Hola' },
	'it-IT': { placeholder: 'Scrivi un messaggio...', flag: '🇮🇹', greeting: 'Ciao' },
	'zh-CN': { placeholder: '写一条消息...', flag: '🇨🇳', greeting: '你好' },
	'ja-JP': { placeholder: 'メッセージを書いてください...', flag: '🇯🇵', greeting: 'こんにちは' },
	'ar-SA': { placeholder: 'اكتب رسالة...', flag: '🇸🇦', greeting: 'مرحبا' },
	
	'en': { placeholder: 'Write a message...', flag: '🇺🇸', greeting: 'Hi' },
	'de': { placeholder: 'Schreibe eine Nachricht...', flag: '🇩🇪', greeting: 'Hallo' },
	'fr': { placeholder: 'Écrivez un message...', flag: '🇫🇷', greeting: 'Salut' },
	'es': { placeholder: 'Escribe un mensaje...', flag: '🇪🇸', greeting: 'Hola' },
	'it': { placeholder: 'Scrivi un messaggio...', flag: '🇮🇹', greeting: 'Ciao' },
	'zh': { placeholder: '写一条消息...', flag: '🇨🇳', greeting: '你好' },
	'ja': { placeholder: 'メッセージを書いてください...', flag: '🇯🇵', greeting: 'こんにちは' },
	'ar': { placeholder: 'اكتب رسالة...', flag: '🇸🇦', greeting: 'مرحبا' },

};

const getVoiceModeFromCookie 	= () => Cookies.get('voice') === 'true';
const getWithMicFromCookie 		= () => Cookies.get('mic') === 'true';

let latestGeneratedImage = null;

// ========== CHAT INPUT =================================================================================================

const ChatInput = ({
	onShowImageOverlay,
	onShowOverlay,
	currentModel,
	onModelChange,
	selectedAvatar,
	user,
	messages,
	setMessages }) => {

	const { language } 										= useContext(LanguageContext); // Sprachwert aus dem Context holen
	const [brainStates, setBrainStates] 					= useState(null);
	const [hormones, setHormones] 							= useState(null);
	// const [isFirstMessageSent, setIsFirstMessageSent] 		= useState(false);
	const [placeholderText, setPlaceholderText] 			= useState(translations[language]?.placeholder || translations['en-US'].placeholder);
	const [currentFlag, setCurrentFlag] 					= useState(translations[language]?.flag || '🌍');
	const [inputValue, setInputValue] 						= useState('');
	const [withVoice, setWithVoice] 						= useState(getVoiceModeFromCookie());
	const [waitingForResponse, setWaitingForResponse] 		= useState(false);
	const [isSpeaking, setIsSpeaking] 						= useState(false);
	const [showLanguageSelector, setShowLanguageSelector] 	= useState(false);
	const avatarName 										= selectedAvatar?.name || 'Avatar';
	const avatarGender 										= selectedAvatar.gender || 'Female';
	const [models, setModels] 								= useState([]);
	const [toolTip, setToolTip] 							= useState('');
	const [versionInfo, setVersionInfo] 					= useState({ version: '', description: '' });
	const timerRef 											= useRef(null);
	const [isTyping, setIsTyping] 							= useState(false); // Benutzeraktivität (Tasteneingabe)
	const [withMic, setWithMic] 							= useState(getWithMicFromCookie()); // Standardmäßig deaktiviert
	const [isMicLocked, setIsMicLocked] 					= useState(true);
	const [inputTokens, setInputTokens] 					= useState(0);
	const [outputTokens, setOutputTokens] 					= useState(0);
	const [balance, setBalance] 							= useState(0);

	
	// console.log("********** withMic: ", withMic);
	
	let selectedLanguage = language;
	
	// ========== PAUSE TIMER ===========================================================================

	const startPauseTimer = () => {
	    // Falls der user nicht gesetzt ist, nicht starten:
	    if (!user) return;
	    
	    if (timerRef.current) {
	      clearTimeout(timerRef.current);
	    }
	    timerRef.current = setTimeout(() => {
	      handlePauseMessage(); 
	      startPauseTimer();
	    }, 5000); // z.B. 5 Sekunden Inaktivität
	  };
	  
	// Startet den Timer, wenn neue Nachrichten empfangen werden
	useEffect(() => {

	}, [messages]);

	
	// useEffect startet den Timer nur, wenn 'user' definiert ist.
	  useEffect(() => {
	    if (user) {
			if (!waitingForResponse) {
				startPauseTimer();
			}
	    }
	    // Beim Unmounten oder wenn sich 'user' ändert, den Timer löschen:
	    return () => {
	      if (timerRef.current) {
	        clearTimeout(timerRef.current);
	      }
	    };
	  }, [user, messages]); // Effekt wird neu gestartet, sobald sich 'user' ändert
	
	
	
	useEffect(() => {
	  setPlaceholderText(translations[language]?.placeholder || translations['en-US'].placeholder);
	  setCurrentFlag(translations[language]?.flag || '🌍');
	}, [language]);
	
	// ========== VERSION ================================================================================

	useEffect(() => {
		fetch('/version.txt?cache-bust=' + new Date().getTime())
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.text();
			})
			.then((text) => {
				const lines = text.split('\n');
				const version = lines.find((line) => line.startsWith('version:'))?.split(':')[1]?.trim();
				const description = lines.find((line) => line.startsWith('description:'))?.split(':')[1]?.trim();
				setVersionInfo({ version, description });
			})
			.catch((error) => {
				console.error('Error loading version information:', error);
			});

		// ========== MODEL ========================================================================================

		const savedModel = Cookies.get('selectedModel'); // Modell aus dem Cookie abrufen
		fetch('/ai_models_list.txt')
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.text();
			})
			.then((text) => {
				const parsedModels = text
					.split('\n')
					.filter((line) => line.trim() !== '') // Entferne leere Zeilen
					.map((line) => {
						const [name, model, description, displayName] = line.split(',').map((item) => item.trim());
						return { name, model, description, displayName };
					});
					
				setModels(parsedModels);

				if (!currentModel) {
					// Suche nach einem spezifischen Eintrag in parsedModels
					const defaultEntry = parsedModels.find((model) => model.name === 'OPENAI_MODEL_3_5_TURBO');

					// Wenn der Eintrag gefunden wird, setze ihn, sonst fallback auf den ersten oder einen Standardwert
					setCurrentModel(defaultEntry?.model || parsedModels[0]?.model || '');
					setToolTip(defaultEntry?.description || parsedModels[0]?.description || '');
				}
			})
			.catch((error) => console.error('Error loading models:', error));

		if (!isGlobalFirstMessageSent) {
			isGlobalFirstMessageSent = true;
			const greetingMessage = "AI:SESSION_START"; // translations[language]?.greeting || translations['en-US'].greeting;
			handleSend(greetingMessage);
		}

	}, []); // Nur einmal beim Laden ausführen


	// ========== MODEL CHANGE =======================================================================================

	const handleModelChange = (e) => {
		const selectedOption = models.find((model) => model.name === e.target.value);
		if (selectedOption) {
			onModelChange(selectedOption.name); // Verwende onModelChange, das in App.js definiert ist
			setToolTip(selectedOption.description || '');
			Cookies.set('selectedModel', selectedOption.name, { expires: 7 }); // Speichere das Modell im Cookie
		}
	};

	// ========== LANGUAGE SELECTOR ==================================================================================

	const toggleLanguageSelector = () => {
		setShowLanguageSelector((prev) => !prev);
	};

	const handleLanguageChange = (newLanguage) => {
		setPlaceholderText(
			translations[newLanguage]?.placeholder || translations['en-US'].placeholder
		);
		setCurrentFlag(translations[newLanguage]?.flag || '🌍');
		Cookies.set('language', newLanguage, { expires: 7 });
		setShowLanguageSelector(false);
	};

	// ========== SEND MESSAGE =======================================================================

	const handleSend = async (message = inputValue) => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current = null;
		}
		if (message.trim()) {
			setWaitingForResponse(true); // Fortschrittsanzeige starten
			setIsMicLocked(true);
			const userMessage = { sender: user.firstName, text: message };


			if (!isGlobalFirstMessageSent2) {
				isGlobalFirstMessageSent2 = true;
				setMessages((prevMessages) => ['']);
			} else {
				setMessages((prevMessages) => [...prevMessages, userMessage]);
			}
			setInputValue('');
			setIsTyping(false);

			try {
				const transformedChats = messages.slice(-5).map((chat) => ({
					role: chat.sender === user.firstName ? 'user' : 'assistant',
					content: chat.text,
				}));

				// let selectedLanguage = getSelectedLanguageFromCookie();

				const payload = {
					avatarName,
					userName: 		user.name,
					email: 			user.email,
					message: 		userMessage.text,
					isFirstSession: messages.length === 0,
					userLanguage: 	language,
					gender: 		avatarGender,
					currentModel: 	currentModel,
				};

				const response = await fetch(`${API_CONFIG.BASE_URL}/kichat`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(payload),
				});

				if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

				const result = await response.text();

				
				// console.log("***************** result: ", result);
				
				// ========== GENERATE IMAGE ==================================================

				const generatedImageMatch = result.match(/generated-image-\d+\.jpg/);
				if (generatedImageMatch) {
					const imageFilename = generatedImageMatch[0];
					latestGeneratedImage = imageFilename;
					setMessages((prevMessages) => [
						...prevMessages,
						{
							sender: selectedAvatar?.name || 'AI',
							text: `Image ready`,
						},
					]);
					const userPrompts = Cookies.get('userPrompts') ? JSON.parse(Cookies.get('userPrompts')) : {};
					const updatedPrompts = { ...userPrompts };
					if (!updatedPrompts[message]) {
						updatedPrompts[message] = [];
					}

					updatedPrompts[message].unshift(imageFilename); // Neuestes Bild an den Anfang setzen
					if (updatedPrompts[message].length > MAX_IMAGES) {
						updatedPrompts[message].pop(); // Entferne das älteste Bild, wenn MAX_IMAGES überschritten wird
					}

					Cookies.set('userPrompts', JSON.stringify(updatedPrompts), { expires: 7 });

				} else {
					console.log('No generated image detected.');
				}

				// ========== LOAD LANGUAGE AND MODEL ==================================================

				const languageMatch 	= result.match(/\{AI:internal:language:([^\}]+)\}/);
				
				console.log("[ChatInput}: languageMatch: ", languageMatch);

				
				const newLanguage 		= languageMatch ? languageMatch[1] : null;
				const modelMatch 		= result.match(/\{AI:internal:model:([^\}]+)\}/);
				const newModel 			= modelMatch ? modelMatch[1] : null;				
				const tokenMatch		= result.match(/\{AI:internal:tokens:([^\}]+)\}/);

				if (tokenMatch) {
	                const tokenValues = tokenMatch[1].split(",").map(value => value.trim());
	                setInputTokens	(parseInt(tokenValues[0], 10));
	                setOutputTokens	(parseInt(tokenValues[1], 10));
					const b = parseFloat(tokenValues[2]);
					setBalance		(b);
					if(b <= 0){
						console.log("******************** SHUTDOWN ***************************");
						handleEndSession();
						return;
					}
	            }
				
				if (newLanguage && translations[newLanguage]) {
					setCurrentFlag(translations[newLanguage].flag || '🌍');
					setPlaceholderText(
						translations[newLanguage].placeholder || translations['en-US'].placeholder
					);
					selectedLanguage = newLanguage;
					
					console.log("[ChatInput}: selectedLanguage: ", selectedLanguage);
					
				}

				// ========== BRAINSTATES & HORMONES ===================================================

				const brainStatesMatch 	= result.match(/\{AI:internal:nhs:states:([^\}]+)\}/);
				const hormonesMatch 	= result.match(/\{AI:internal:nhs:hormones([^\}]+)\}/);
				const brainStatesData 	= brainStatesMatch ? brainStatesMatch[1] : null;
				const hormonesData 		= hormonesMatch ? hormonesMatch[1] : null;
				setBrainStates			(brainStatesData);
				setHormones				(hormonesData);
				setBrainStates			(brainStatesMatch ? brainStatesMatch[1] : null);
				setHormones				(hormonesMatch ? hormonesMatch[1] : null);
				
				
				// console.log("************* brainStates: ", brainStates);
				// console.log("************* hormones:    ", hormones);
				

				// =========== CLEAN MESSAGE ===========================================================

				let cleanMessage = result.split('{AI:internal:start}')[0].trim();

				// Überprüfung auf "{AI:shutdown}" und Herausfiltern
				let isShutdown = false;
				if (result.includes('{AI:shutdown}')) {
					isShutdown = true;
					cleanMessage = cleanMessage.replace('{AI:shutdown}', '').trim(); // "{AI:shutdown}" entfernen
				}

				// =========== ADD NAME: ===============================================================

				const botMessage = { sender: selectedAvatar.name, text: cleanMessage };

				// =========== LOAD MESSAGE INTO THE CHAT WINDOW & SPEECH OUTPUT ========================

				if(!generatedImageMatch){
					setMessages((prevMessages) => [...prevMessages, botMessage]);
					await playSpeech(cleanMessage);

					if (isShutdown) {
						setTimeout(() => {
							console.log('Shutting down the application.');
							handleEndSession();
						}, 15000); // 3 minuten
					} else {
						startPauseTimer(); // Timer neu starten, wenn kein Shutdown-Signal vorhanden ist
					}
				}
			} catch (error) {
				console.error('Error while calling the API:', error);
				//setMessages((prevMessages) => [...prevMessages, { sender: 'System', text: 'Error loading the response.' },]);
			} finally {
				setWaitingForResponse(false);
			}
		}
	};

	function removeEmojis(text) {
	    return text.replace(/[\p{Emoji}\p{Extended_Pictographic}]/gu, '');
	}
	
	// =========== VOICE AND MIC CHANGE ===============================================================================

	const toggleVoice = () => {
		const newVoiceState = !withVoice;
		setWithVoice(newVoiceState);
		Cookies.set('voice', newVoiceState.toString(), { expires: 7 });
	};

	const toggleMic = () => {
		const newMicState = !withMic;
		setWithMic(newMicState);
		Cookies.set('mic', newMicState.toString(), { expires: 7 });
	};

	// =========== PLAY VOICE ===================================================================================

	const [userInteracted, setUserInteracted] = useState(true);

	const handleUserInteraction = () => {
	  if (!userInteracted) {
	    setUserInteracted(true);
	  }
	};

	const playSpeech = async (text) => {
	    let cleanMessage = text.split('{AI:internal:start}')[0].trim();
	    let cleanedText = removeEmojis(cleanMessage);
	    
	    if (!withVoice || !text) return;

        setIsMicLocked(true);

	    try {
	        const audioBlob = await generateSpeech(cleanedText, selectedAvatar.voice, selectedLanguage, avatarGender);
	        if (audioBlob) {
	            try {
	                const audioUrl = URL.createObjectURL(audioBlob);
	                // Falls bereits ein Audio aktiv ist, stoppen und freigeben
	                if (audioRef.current) {
	                    console.log("Bereits laufendes Audio stoppen.");
	                    audioRef.current.pause();
	                    URL.revokeObjectURL(audioRef.current.src);
	                    audioRef.current = null;
	                }
	                const audio = new Audio(audioUrl);
	                audioRef.current = audio;
	                setIsSpeaking(true);
	                
	                // onended-Callback: Wenn das Audio zu Ende ist, freigeben
	                audio.onended = () => {
	                    console.log("Audio playback ended.");
	                    URL.revokeObjectURL(audioUrl);
	                    audioRef.current 	= null;
						setIsSpeaking		(false);
					    setIsMicLocked		(false);
	                };
				    audio.play();
	                return;
	            } catch (error) {
	                setMessages((prevMessages) => [
	                    ...prevMessages, 
	                    { sender: 'System', text: 'Error 3546 while playing the audio.' + error }
	                ]);
	                console.error('Error while playing the audio:', error);
	            }
	        } else {
	            setMessages((prevMessages) => [
	                ...prevMessages, 
	                { sender: 'System', text: 'Error 8955 AudioBlob wurde nicht zurückgegeben!' + error }
	            ]);
	            console.error('AudioBlob wurde nicht zurückgegeben!');
	        }
	    } catch (error) {
	        setMessages((prevMessages) => [
	            ...prevMessages, 
	            { sender: 'System', text: 'Error 3396 Error while calling the Speech API!' + error }
	        ]);
	        console.error('Error while calling the Speech API:', error);
	    }
	    
	    // Falls ein Fehler auftritt, Flags zurücksetzen
	    setIsSpeaking(false);
        setIsMicLocked(false);
	};
	
		
	// =========== SPEECH INPUT ==============================================================================================

	const handleSpeechDetected = (text) => {
		// Stop the audio output
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current = null;
			setIsSpeaking(false);
		}
		console.log('Speech detected:', text);
		setInputValue((prev) => prev + ' ' + text); // Erkannten Text ins Eingabefeld übernehmen
	};

	const handleSendImageCommand = (e) => {
	  e.preventDefault();
	  if (!inputValue.trim()) {
	    alert("Bitte geben Sie eine Nachricht ein, um den Bildbefehl zu senden.");
	    return;
	  }
	  const command = `#gi:${inputValue}`;
	  handleSend(command);
	  
	  // handleShowImage();
	  
	};


	// ========== SEND PAUSE MESSAGE =======================================================================================

	const handlePauseMessage = async () => {
		try {
			const response = await fetch(`${API_CONFIG.BASE_URL}/kichat`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					avatarName: 	selectedAvatar?.name,
					userName: 		user.name,
					email: 			user.email,
					message: 		'AI:PAUSE',
					userLanguage: 	language,
					currentModel: 	currentModel,
				}),
			});

			if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

			let result = await response.text();

			if (result.trim() !== 'AI:OK') {
				// Überprüfen, ob "{AI:shutdown}" im Ergebnis vorkommt
				let isShutdown = false;

				if (result.includes('{AI:shutdown}')) {
					isShutdown = true; // Flag setzen
					result = result.replace('{AI:shutdown}', '').trim(); // "{AI:shutdown}" aus dem Ergebnis entfernen
				}
				
				let cleanMessage = result.split('{AI:internal:start}')[0].trim();


				// Nachricht hinzufügen
				const botMessage = { sender: selectedAvatar?.name || 'AI', text: cleanMessage.trim() };
				setMessages((prev) => [...prev, botMessage]);

				// Sprachausgabe
				await playSpeech(result);

				if (isShutdown) {
					setTimeout(() => {
						handleEndSession(); // Funktion wird aufgerufen
					}, 8000);
				} else {
					// Andernfalls Pause-Timer starten
					startPauseTimer();
				}
			}
		} catch (error) {
			console.error('Error while sending pause message:', error);
		}
	};

	// ========== INPUT CHANGE =============================================================================================

	const handleInputChange = (e) => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current = null;
			setIsSpeaking(false);
			setIsMicLocked(false);
		}
		setInputValue(e.target.value);
		setIsTyping(true);
		startPauseTimer();
	};

	// =========== KEY PRESSED =============================================================================================

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleSend();
		} else {
			setIsTyping(true); // Tippen erkannt
			startPauseTimer(); // Timer zurücksetzen
		}
	};

	// =========== CLEAR TIME =============================================================================================

	useEffect(() => {
		return () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		};
	}, []);

	// =========== END SESSION =============================================================================================

	const handleEndSession = () => {
		if (audioRef.current) {
			audioRef.current.pause();
			audioRef.current = null;
		}
		window.location.reload(); // Neustart der Anwendung
	};

	// =========== SHOW BAINSTATES & HORMONES ==============================================================================

	const handleShowValues = () => {
		const avatarName = selectedAvatar?.name; // Aus den Props holen
		const userEmail = user?.email;

		if (!avatarName || !userEmail) {
			console.error('Avatar name or user email is missing.');
			return;
		}

		if (!brainStates || !hormones) {
			console.error('BrainStates or Hormones data is missing.');
			return;
		}

		if (onShowOverlay) {
			onShowOverlay(brainStates, hormones, avatarName, userEmail);
		} else {
			console.error('******* onShowOverlay is not defined');
		}
	};

	// =========== SHOW IMAGE ===============================================================================================

	const handleShowImage = (e, fileName) => {
		e.preventDefault(); // Verhindert Standardaktionen wie einen Seiten-Reload


		if (!fileName) {
			// Versuche, den fileName aus einem Cookie zu laden
			fileName = Cookies.get('latestGeneratedImage');

			if (!fileName) {
				// Weder fileName noch Cookie vorhanden
				console.error('No generated image available.');
				alert('No generated image available to display.');
				return;
			}
		}

		// Speichere den fileName im Cookie
		Cookies.set('latestGeneratedImage', fileName, { expires: 1 });

		const chatEmail = selectedAvatar?.name + "_" + user.email;

		// Erstelle die URL und öffne das Bild
		const url = `${API_CONFIG.BASE_URL}/loadGeneratedImage?chatEmail=${chatEmail}&filename=${fileName}`;
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	
	const MAX_IMAGES = 10; // Maximal 10 Bilder speichern

	const getRecentImages = () => {
	    const storedImages = Cookies.get('userPrompts') ? JSON.parse(Cookies.get('userPrompts')) : {};
	    const allImages = Object.entries(storedImages)
	        .flatMap(([prompt, images]) => images.map(image => ({ prompt, image }))) // Liste aller Bilder mit Prompt
	        .sort((a, b) => b.image.timestamp - a.image.timestamp); // Nach Zeitstempel sortieren (neueste zuerst)

	    return allImages.slice(0, MAX_IMAGES); // Nur die letzten 10 behalten
	};
	
	/* ========== JSX ======================================================================================================== */

	return (


		<div className="chat-input">

			{/* ========== PROGRESS BAR ========================================================================================= */}

			<ProgressBar isLoading={waitingForResponse}/>

			{/* ========== INPUT FIELD ========================================================================================= */}

			<div className={`main-content ${waitingForResponse ? 'shrink' : ''}`}>
			<textarea
			                    placeholder="Write a message..."
			                    className="input-field"
			                    value={inputValue}
								onChange={handleInputChange} 
			                    
			                    onKeyPress={(e) => {
			                        if (e.key === 'Enter' && !e.shiftKey) {
			                            e.preventDefault();
			                            handleSend();
			                        }
			                    }}
			                />
					
			</div>


			{/* =========== BUTTON CONATINER ==================================================================================== */}

			<div className="button-container2">

				{/* ---------- AVATAR --------------------------------------------------------------------------------------------- */}
				
				{/*
				<div className={`avatar-container ${waitingForResponse ? 'avatar-pulsing' : 'avatar-not-pulsing'}`}>
					<Avatar avatar={selectedAvatar} selectedLanguage={language} />
				</div>
				*/}
				
				{/* ---------- VOICE - MIC  ---------------------------------------------------------------------------------------- */}

				<div className="vertical-container">
				
					{/* ---------- VOICE ---------------------------------------------------------------------------------------- */}
			
					<button className={`voice-button ${withVoice ? 'active' : ''}`} onClick={toggleVoice} title="Toggle Voice Mode"><FaVolumeUp size={20} /></button>

					{/* ---------- MIC ---------------------------------------------------------------------------------------- */}

					<button className={`mic-button ${withMic && !isMicLocked && !isSpeaking ? 'active' : ''}`} onClick={toggleMic} title="Toggle Microphone Mode"><FaMicrophone size={20} /></button>
				</div>

				{/* ---------- LANGUAGE & IMAGE ---------------------------------------------------------------------------------------- */}

				<div className="vertical-container align-start" style={{ alignItems: 'flex-start' }}>
				
					{/* ---------- VOICE ---------------------------------------------------------------------------------------- */}

					<button className="language-button2" onClick={toggleLanguageSelector} title="Change Language" size={20}>{currentFlag}</button>
					
					{/* ---------- IMAGE ---------------------------------------------------------------------------------------- */}

					<button className="show-image-button" onClick={handleSendImageCommand}><FaImage size={20} /></button>					
				</div>
				
				{showLanguageSelector && (
					<div className="popup-overlay" onClick={toggleLanguageSelector}>
						<div className="language-selector-popup" onClick={(e) => e.stopPropagation()}>
							<LanguageSelector onLanguageChange={handleLanguageChange} defaultLanguage={language} />
							<button className="close-popup-button" onClick={toggleLanguageSelector} title="Close">Close</button>
						</div>
					</div>
				)}

				{/* ---------- EMOTION & MODEL ------------------------------------------------------------------------------------- */}

				<div className="vertical-container">
					<button onClick={handleShowValues} className="emotions-button">E</button>
					
					<select
					    className="emotions-model-button"
					    onChange={(e) => handleShowImage(e, e.target.value)}
					    title="Select a previously generated image"
					>
					    <option value="">I</option>
					    {Object.entries(Cookies.get('userPrompts') ? JSON.parse(Cookies.get('userPrompts')) : {})
					        .slice(-MAX_IMAGES) // Nur die letzten 10 Prompts behalten
					        .map(([prompt, images], index) =>
					            images.slice(0, MAX_IMAGES).map((image, idx) => (
					                <option key={`${prompt}-${idx}`} value={image} title={`Prompt: ${prompt}`}>
					                    {`Generated Image ${index * MAX_IMAGES + idx + 1}`}
					                </option>
					            ))
					        )}
					</select>
					
					{/*
					<div className="model-selector">
						<label htmlFor="model-dropdown" className="label-with-spacing"></label>
						<select id="model-dropdown" className="emotions-model-button" value={currentModel} onChange={handleModelChange} title={toolTip} >
							{models.map((model) => (
								<option key={model.name} value={model.name} title={model.description}>
									{model.displayName}
								</option>
							))}
						</select>
					</div>
					*/}
				</div>

				{/* ---------- SHOW IMAGE ------------------------------------------------------------------------------------------ */}

				<div className="vertical-container">





					
					
										
					
				</div>
				<div className="vertical-container-tokens">
				<p style={{ margin: 0 }}>
				        <strong>Tokens:</strong> {(inputTokens / 1_000_000).toFixed(2)} M <strong>/ </strong> {(outputTokens / 1_000_000).toFixed(2)} M
				    </p>

				    <p style={{ margin: 0 }}>
				        <strong>Balance:</strong> {balance.toFixed(2)} USD
				    </p>
					<p style={{ margin: 0 }}>
						<strong>version:</strong> {versionInfo.version}
					</p>
				</div>


				{/* ---------- END SESSION ------------------------------------------------------------------------------------------ */}

				<button className="end-session-button" title="End Session" onClick={handleEndSession} ><FiPower size={24} /></button>

			</div>

			{/* ---------- END SESSION ------------------------------------------------------------------------------------------ */}


			<AzureSpeechRecognizer
				subscriptionKey		= "5rFSijVIVRkGTZARusTpRpNdqUjRoaSWQ9CaDc08kpwoB596wV6kJQQJ99BAACPV0roXJ3w3AAAYACOG0D6l"
				region				= "germanywestcentral"
				withMic				= {withMic}
				isMicLocked			= {isMicLocked}
				onSpeechDetected	= {(text) => {
					console.log('Speech detected:', text); // Erkannten Text anzeigen
				}}
				onPauseDetected={(text) => {
					console.log('Pause detected. Sending text:', text); // Text nach 2 Sekunden Pause
					handleSend(text); // Sende die Nachricht
				}}
			/>
			
			{/*
			<SpeechInterruptDetector onInterrupt={() => {
			    if (audioRef.current) {
			        audioRef.current.pause();
			        audioRef.current = null;
			        setIsSpeaking(false);
			        setIsMicLocked(false);
			    }
			}} />
			
			*/}
		</div>

	);
};

// Stelle sicher, dass die `export` Anweisung am Ende steht.
export default ChatInput;