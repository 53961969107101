// ==============================================================================
//
// App.js
//
// ==============================================================================

import React, { useState, useRef, useEffect, useContext } from 'react';

import './App.css';
import Cookies 				from 'js-cookie';
import Login 				from './components/Login.js';
import AvatarSelection 		from './components/AvatarSelection.js';
import ChatWindow 			from './components/ChatWindow.js';
import ChatInput 			from './components/ChatInput.js';
import ImageOverlay 		from './components/ImageOverlay.js';
import ValuesOverlay 		from './components/ValuesOverlay';
import API_CONFIG 			from './config';
import PricingPage 			from './components/PricingPage';
import Consent 				from './components/Consent';
import { useTranslation } 	from 'react-i18next';

// ========== CONTEXTS ===========================================================

import { GlobalStateProvider } 				from './context/GlobalStateContext';
import { AvatarProvider, AvatarContext } 	from './context/AvatarContext.js';
import { LanguageContext } 					from './context/LanguageContext.js';

// ========== APP ================================================================

const App = () => {
	
  	const [user, setUser] 								= useState(null);
  	const [selectedAvatar, setSelectedAvatar] 			= useState(null);
  	const [messages, setMessages] 						= useState([]);
  	const [referrer, setReferrer] 						= useState(null);
  	const stackContainerRef 							= useRef(null);
	const [brainStates, setBrainStates] 				= useState(null); 
	const [brainStatesAvatar, setBrainStatesAvatar] 	= useState(null);
	const [hormones, setHormones] 						= useState(null); 
	const [hormonesAvatar, setHormonesAvatar] 			= useState(null);	
  	const [currentModel, setCurrentModel] 				= useState(() => Cookies.get('currentModel') || 'OPENAI_MODEL_3_5_TURBO');
  	Cookies.set('initialized', 'false', { expires: 1 });
  	const [showOverlay, setShowOverlay] 				= useState(false);
	const [showImageOverlay, setShowImageOverlay] 		= useState(false);
	const [imageUrl, setImageUrl] 						= useState('');
	const { language } 									= useContext(LanguageContext);
	const [showConsent, setShowConsent]					= useState(false);
	
	// console.log("🌍 [App.js] Sprache aus Context:", language); // ✅ Prüfen, ob es ankommt
	
	useEffect(() => {
	  const storedUser = localStorage.getItem("user");

	  if (!storedUser) {
	    console.log("🚨 Kein Benutzer gefunden. Lösche alle Benutzerdaten.");
	    setUser(null);
	  }
	}, []);

	
	const handleShowOverlay = (states, hormones, statesAvatar, hormonesAvatar, avatarName, userEmail) => {
	  		setBrainStates(states);
	  		setHormones(hormones);
			setBrainStatesAvatar(statesAvatar);
			setHormonesAvatar(hormonesAvatar);
	  		setShowOverlay(true); // Aktiviert die Überlagerung
    };	

	// ========== LANGUAGE ========================================================
	
	
	const changeLanguage = (newLang) => {
	    setLanguage(newLang); // React State updaten
	    // Cookies.set('language', newLang, { expires: 7 }); // Cookie setzen
	};
	
	
	// ========== OPEN IN NEW TAB =================================================
	
	const openImageInNewTab = (url) => {
	  // console.log('*** in openImageInNewTab: URL:', url);
	  window.open(url, '_blank');
	};
	
	//console.log('handleShowImageOverlay exists:', typeof handleShowImageOverlay === 'function');

    const handleCloseOverlay = () => {
      setShowOverlay(false); // Deaktiviert die Überlagerung
    };

	const handleDownloadImage	= () => {
	     setShowOverlay(false); // Deaktiviert die Überlagerung
	};
	   
   // Avatare aus dem Kontext abrufen
  
   const avatars = useContext(AvatarContext);

   const defaultCookies = {
		currentModel: 'OPENAI_MODEL_3_5_TURBO',
		language: 'en-US',
		voice: 'false',
	};

	const resetAllCookies = () => {
	  const allCookies = Cookies.get(); // Alle Cookies abrufen
	  for (const cookieName in allCookies) {
	    Cookies.remove(cookieName); // Jeden Cookie entfernen
	  }
	  // console.log('All cookies cleared!');
	  
	  // OPENAI_MODEL_3_5_TURBO
	  
	};

	// ========== USE EFFECT - ALWAYS =================================================
	
    useEffect(() => {
	
	// resetAllCookies();		
		
	const paymentSuccess = document.cookie.includes("paymentSuccess=true");

	if (!paymentSuccess && (!user || !user.plans || user.plans.length === 0)) {
	  localStorage.removeItem('user');
	  console.log("✅ Benutzer wurde entfernt, weil Zahlung nicht erkannt wurde.");
	} else {
	  console.log("✅ Benutzer bleibt eingeloggt, weil Zahlung erkannt wurde.");
	}
	
    if (
      process.env.NODE_ENV === 'production' &&
      window.location.protocol !== 'https:' &&
      !window.location.hostname.includes('localhost')
    ) {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }

	// resetAllCookies();
	
	Object.keys(defaultCookies).forEach((cookieName) => {
      if (!Cookies.get(cookieName)) {
        Cookies.set(cookieName, defaultCookies[cookieName], { expires: 7 }); // Setze Standardwert mit 7 Tagen Gültigkeit
        // console.log(`Cookie ${cookieName} initialisiert mit Wert: ${defaultCookies[cookieName]}`);
      }
    });
	
	// Synchronisiere den State mit dem `currentModel`-Cookie
    const modelFromCookie = Cookies.get('currentModel') || defaultCookies.currentModel;
    setCurrentModel(modelFromCookie);
	
    const currentDomain = window.location.hostname;

    if (currentDomain === 'ramin.life') {
		  Cookies.set('initialized', 'true', { expires: 1 });

      const targetUrl = `http://mypal.life?referrer=ramin.life`;
      window.location.href = targetUrl;
    } else {
      const params = new URLSearchParams(window.location.search);
      const referrerParam = params.get('referrer');
      setReferrer(referrerParam);
    }

    const updateLayout = () => {
      if (stackContainerRef.current) {
        const chatInputHeight = document.querySelector('.chat-input')?.offsetHeight || 80;
        const viewportHeight = window.innerHeight;
        stackContainerRef.current.style.height = `${viewportHeight - chatInputHeight}px`;
      }
    };
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => {
      window.removeEventListener('resize', updateLayout);
    };
}, []);

	// Im App-Rendering dann:
	useEffect(() => {
  	if (user && user.email) {
    	checkConsent(user.email).then((consent) => {
      	if (!consent) {
        	// Zeige die Consent-Komponente
        	setShowConsent(true);
      	} else {
        	setShowConsent(false);
      	}
    	});
  	}
}, [user]);

const checkConsent = async (email) => {
  try {
    const response = await fetch(`${API_CONFIG.BASE_URL}/isConsent?email=${encodeURIComponent(email)}`);
    const isConsent = await response.json();
    return isConsent;
  } catch (err) {
    console.error('Error checking consent:', err);
    return false;
  }
};

const getFlagForLanguage = (language) => {
    const flags = {
      en: '🇺🇸',
      de: '🇩🇪',
      fr: '🇫🇷',
      es: '🇪🇸',
      it: '🇮🇹',
      zh: '🇨🇳',
      ja: '🇯🇵',
      ar: '🇸🇦',
    };
    return flags[language.split('-')[0]] || '🌍';
};

// ========== LOGIN ==================================================================

if (!user) 
{
    return <Login 	setUser={setUser} referrer={referrer} />;
}

// ========== FIRST PAY ==============================================================

if (!user.plans || user.plans.length == 0 || user.balance <= 0) {
	return <PricingPage user={user} />;
}

// ========== CONTINUE IF THE USER HAS A PLAN & CONSENT? =============================

if(showConsent){
	return (
	<>
    	{showConsent && <Consent user={user} setUser={setUser} />}
	</>
	);
}

// ========== REFFERRER RAMIN ========================================================

if (referrer === 'ramin.life') {
	
	Cookies.set('initialized', 'true', { expires: 1 });
	
    if (avatars.length > 0 && !selectedAvatar) {
      setSelectedAvatar(avatars[0]); // Standard-Avatar aus dem Kontext setzen
      initializeChat(user, avatars[0], setMessages);
    }

    return (
      <div className="app-container">
        <div className="stack-container" ref={stackContainerRef}>
          <ChatWindow messages={messages} user={user} />
        </div>
		
        <ChatInput
			currentModel={currentModel}
			onModelChange={(newModel) => {
			setCurrentModel(newModel); // Aktiviere das neue Modell im State
			Cookies.set('currentModel', newModel, { expires: 7 }); // Speichere das Modell im Cookie
			}}
			onShowOverlay={handleShowOverlay} 
          user={user}
          selectedAvatar={avatars[0]}
          messages={messages}
          setMessages={setMessages}
          flag={getFlagForLanguage(user.language)}
        />
		
      </div>
    );
  }

  const handleCloseImageOverlay = () => {
    setShowImageOverlay(false);
    setImageUrl('');
  };
  
	// ========== AVATAR SELECTION ==================================================================
	
  if (!selectedAvatar) {

    return (
      <AvatarSelection
        user={user}
        setSelectedAvatar={async (avatar) => {
          // console.log('AvatarSelection callback executed');
          // console.log('Avatar selected:', avatar);
          setSelectedAvatar(avatar);
          // await initializeChat(user, avatar, setMessages);
        }}
      />
    );
	
	
  } else {
		Cookies.set('initialized', 'true', { expires: 1 });
  }

  {/* ========== JSX ================================================================================================== */}

  return (
	<GlobalStateProvider>
  	<div className="app-container">
    	{showOverlay && (
			<ValuesOverlay
			  brainStates     		= {brainStates}
			  hormones        		= {hormones}
			  avatarName      		= {selectedAvatar?.name} // Avatarname wird übergeben
			  userEmail       		= {user?.email}
			  setBrainStates  		= {setBrainStates} // Hier hinzufügen
			  setHormones     		= {setHormones}     // Hier hinzufügen
			  setBrainStatesAvatar	= {setBrainStatesAvatar} // Hier hinzufügen
			  setHormonesAvatar     = {setHormonesAvatar}     // Hier hinzufügen
			  onClose         		= {handleCloseOverlay} 
			  
			/> )
		}

		{showImageOverlay && (
		  <ImageOverlay
		    imageUrl         = {imageUrl}
		    onClose          = {handleCloseImageOverlay}
			onDownloadImage  = {handleDownloadImage}
		  />
		)}
		
		{/* ========== CHAT WINDOW ========================================================================= */}
		
    	<div className="stack-container" ref={stackContainerRef}>
      		<ChatWindow messages={messages} user={user} />
    	</div>

		{/* ========== CHAT INPUT ========================================================================= */}
		
    	<ChatInput
      		currentModel  = {currentModel}
      		onModelChange = {(newModel) => {
        		setCurrentModel(newModel); // Aktiviere das neue Modell im State
        		Cookies.set('currentModel', newModel, { expires: 7 }); // Speichere das Modell im Cookie
      		}}
			
			
      		onShowOverlay       = {handleShowOverlay}
      		user                = {user}
      		selectedAvatar      = {selectedAvatar}
      		messages            = {messages}
      		setMessages         = {setMessages}
      		flag                = {getFlagForLanguage(language)}
    	/>

  	</div>
	
	</GlobalStateProvider>
  );
  
  
  
};

export default () => (
  <AvatarProvider>
    <App />
  </AvatarProvider>
);
