import React from 'react';
import './ImageOverlay.css';

const ImageOverlay = ({ imageUrl, onClose, onDownloadImage }) => {
  return (
    <div className="image-overlay">

      <div className="image-container">
        <img src={imageUrl} alt="Generated" />
		<div className="button-container">
          <button className="close-overlay-button" onClick = {onClose}>
             Close
          </button>

		  <button className="download-image-button" onClick = {onDownloadImage}>
		     Download
		  </button>
		  
        </div>
      </div>
    </div>
  );
};

export default ImageOverlay;
