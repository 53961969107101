import React, { createContext, useState } from 'react';

// Erstelle den Context
export const GlobalStateContext = createContext();

// Provider-Komponente
export const GlobalStateProvider = ({ children }) => {
  const [brainStates, setBrainStates] = useState(null);
  const [hormones, setHormones] = useState(null);
  const [brainStatesAvatar, setBrainStatesAvatar] = useState(null);
  const [hormonesAvatar, setHormonesAvatar] = useState(null);

  return (
    <GlobalStateContext.Provider
      value={{
        brainStates,
        setBrainStates,
        hormones,
        setHormones,
        brainStatesAvatar,
        setBrainStatesAvatar,
        hormonesAvatar,
        setHormonesAvatar,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
