import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import GoogleLogin from './GoogleLogin.js';
import FacebookLogin from './FacebookLogin.js';
import ManualLogin from './ManualLogin.js';
import TrialForm from './TrialForm.js';
import translations from './translations.js';
import API_BASE_URL from '../config.js';
import { LanguageContext } from '../context/LanguageContext';

import './Login.css';

const Login = ({ setUser, referrer }) => { // Referrer als Prop hinzufügen
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate(); // 🚀 Für Navigation
  const [manualLogin, setManualLogin] = useState(false);
  const [showTrialForm, setShowTrialForm] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);

  useEffect(() => {
    console.log('🔄 Sprache in Login.js aktualisiert:', language);
  }, [language]); // 🔥 useEffect triggert sich, wenn sich die Sprache ändert

  console.log("*********** Language: ", language);

  const handleClose = () => {
    setIsLoginFormVisible(false); // Login-Formular wird ausgeblendet
  };

  const togglePopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage('');
  };

  const handleTrialSubmit = ({ name, email }) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 14);

    Cookies.set(
      'trialUser',
      JSON.stringify({ name, email, startDate: new Date(), endDate: expirationDate }),
      { expires: 14 }
    );

    setUser({
      firstName: name.split(' ')[0],
      email,
      language: language,
      trialExpires: expirationDate,
    });

    togglePopup(
      translations[language]?.trialStarted ||
      'Your 14-day trial has started. Enjoy exploring the app!'
    );

    setShowTrialForm(false);
  };

  const welcomeMyPal = (translations[language]?.welcome || 'Welcome to') + ' myPal';

  // 🚀 **Benutzer-Daten aus Cookie abrufen & automatisch weiterleiten**
  
  useEffect(() => {
    const storedUser = Cookies.get("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      console.log("✅ Benutzer aus Cookie geladen:", parsedUser);

      if (parsedUser.plans && parsedUser.plans.length > 0) {
        console.log("✅ Benutzer hat einen Plan, navigiere weiter...");
        navigate("/"); // **Direkte Weiterleitung zur Hauptseite**
      } else {
        console.log("⚠️ Kein Plan gefunden, bleibt auf der PricingPage.");
      }
    }
  }, []);

  return (
    <div className="login-container">
      <div className="home-container">
        <a href="/" className="home-button">🏠 Home</a>
      </div>

      {/* ========== LANGUAGE SELECTOR =============================================== */}
      <h1>
        {referrer === 'ramin.life' ? (translations[language]?.welcome || 'Welcome to') + ' Ramin' :
          (translations[language]?.welcome || 'Welcome to myPal')
        }
      </h1>

      {!manualLogin && !showTrialForm ? (
        <div className="login-buttons-container">
          <div className="login-icons-row">

            {/* ========== GOOGLE LOGIN ================================================== */}
            <GoogleLogin
              setUser		= {setUser}
              language		= {language}
              translations	= {translations}
            />

            {/* ========== FACEBOOK LOGIN ================================================== */}
            <FacebookLogin
              setUser		= {setUser}
              language		= {language}
              translation	= {translations}
            />

            {/* ========== MANUAL LOGIN ================================================== */}
            <button className="login-button-1" onClick={() => setManualLogin(true)}>
              {translations[language]?.login || 'Manual Login'}
            </button>

          </div>
        </div>
      ) : manualLogin ? (
        <ManualLogin
          setUser			= {setUser}
          language			= {language}
          togglePopup		= {togglePopup}
          translations		= {translations}
          API_BASE_URL		= {API_BASE_URL}
          onClose={() => setManualLogin(false)} // Close-Funktion korrekt übergeben
        />
      ) : (
        <TrialForm
          onSubmit={handleTrialSubmit}
          translations={translations}
          language={language}
          togglePopup={togglePopup}
        />
      )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
