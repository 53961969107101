import React, { useEffect, useRef } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const AzureSpeechRecognizer = ({
  subscriptionKey,
  region,
  withMic,
  isMicLocked,
  onSpeechDetected,
  onPauseDetected,
}) => {
  const recognizerRef = useRef(null);
  const pauseTimerRef = useRef(null); // Timer für Pause
  const lastSpokenTextRef = useRef(''); // Letzter erkannter Text

  useEffect(() => {
	
	// console.log("[AzureSpeechRecognizer].isMicLocked: ", isMicLocked);
	
    if (!withMic || isMicLocked) {
      if (recognizerRef.current) {
        recognizerRef.current.stopContinuousRecognitionAsync();
		recognizerRef.current.close();
		recognizerRef.current = null;
      }
      return;
    }

    const audioConfig 						= sdk.AudioConfig.fromDefaultMicrophoneInput();
    const speechConfig 						= sdk.SpeechConfig.fromSubscription(subscriptionKey, region);
    speechConfig.speechRecognitionLanguage 	= 'de-DE';

    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    recognizerRef.current = recognizer;

    // Erkanntes Ereignis
    recognizer.recognized = (sender, event) => {
		if (!withMic || isMicLocked) {
	       console.log("❌ Erkennung ignoriert: Mikrofon deaktiviert.");
	       return;
	    }
      	if (event.result.text) {
        	lastSpokenTextRef.current = event.result.text; // Speichere den Text
        	onSpeechDetected(event.result.text); // Gebe Text zurück
        	resetPauseTimer(); // Pause-Timer zurücksetzen
      	}
    };

    // Starten der kontinuierlichen Spracherkennung
    recognizer.startContinuousRecognitionAsync(
      () => /*console.log('Speech recognition started.') */
      (error) => console.error('Speech recognition failed:', error)
    );

    const resetPauseTimer = () => {
      if (pauseTimerRef.current) clearTimeout(pauseTimerRef.current);
      pauseTimerRef.current = setTimeout(() => {
        if (lastSpokenTextRef.current.trim()) {
          onPauseDetected(lastSpokenTextRef.current); // Sende Text bei Pause
          lastSpokenTextRef.current = ''; // Letzten Text zurücksetzen
        }
      }, 1000); // 2 Sekunden Pause
    };

    return () => {
      recognizer.stopContinuousRecognitionAsync();
      recognizer.close();
      recognizerRef.current = null;
      if (pauseTimerRef.current) clearTimeout(pauseTimerRef.current);
    };
  }, [withMic, isMicLocked, subscriptionKey, region, onSpeechDetected, onPauseDetected]);

  return null;
};

export default AzureSpeechRecognizer;
