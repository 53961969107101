import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
// ❌ Entferne LanguageDetector, weil du bereits eine zentrale Verwaltung hast

import Cookies from 'js-cookie';

console.log("🔥 `i18n.js` wird geladen...");

// Hole die Sprache aus dem Cookie oder setze eine Standardsprache
const storedLanguage = Cookies.get('language') || 'en-US';
console.log("🌐 [i18n] Initiale Sprache aus Cookie:", storedLanguage);

i18n
  .use(Backend) // Lädt Übersetzungen aus JSON-Dateien
  .use(initReactI18next) // Bindet i18next an React
  .init({
    lng: storedLanguage, // ❗ Setzt die Sprache manuell aus deinem `LanguageContext`
    fallbackLng: 'en-US', // Falls nichts erkannt wird → Standard auf Englisch
    debug: true, // Debug-Modus für Entwicklung
    interpolation: { escapeValue: false }, // Sicherheit gegen XSS
    backend: {
      loadPath: '/{{lng}}/translation.json', // Pfad zu den Übersetzungsdateien
    }
  })
  .then(() => console.log("✅ `i18next` erfolgreich initialisiert mit Sprache:", i18n.language))
  .catch(error => console.error("❌ Fehler bei `i18next`:", error));

export default i18n;
