import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({ isLoading, text }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let interval;

        if (isLoading) {
            // Fortschritt zurücksetzen und Intervall starten
            setProgress(0);
            interval = setInterval(() => {
                setProgress((prev) => (prev < 100 ? prev + 10 : 0)); // Wiederholung
            }, 500); // Fortschritt alle 500ms erhöhen
        } else {
            setProgress(0); // Fortschrittsbalken zurücksetzen
        }

        return () => clearInterval(interval); // Intervall beim Beenden aufräumen
    }, [isLoading]);

    return (
        <div className={`progress-bar-container ${isLoading ? 'visible' : 'hidden'}`}>
            {isLoading && (
                <>
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProgressBar;
