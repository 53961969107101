import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 			"AIzaSyD1WZkbburizugXjt0fB2pDzkfF9ZdmaQs",
  authDomain: 		"mypal-life-login.firebaseapp.com",
  projectId: 		"mypal-life-login",
  storageBucket: 	"mypal-life-login.firebasestorage.app",
  messagingSenderId: "164155427597",
  appId: 			"1:164155427597:web:0ab87f9039af465204fe44"
};


const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
