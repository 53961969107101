import API_CONFIG 				from '../config.js';

/**
 * Sendet Text, Stimme und Sprache an den Azure Speech Endpoint und gibt die generierte Audiodatei zurück.
 * @param {string} text - Der Text, der gesprochen werden soll.
 * @param {string} voice - Die Stimme, die verwendet werden soll (z. B. "en-US-GuyNeural").
 * @param {string} language - Die Sprache, die verwendet werden soll (z. B. "de-DE").
 * @returns {Promise<Blob>} - Eine Promise, die die Audiodatei als Blob zurückgibt.
 */
export async function generateSpeech(text, voice, language, gender, rate = "2.0") {
  if (!text) {
    throw new Error("Text darf nicht leer sein.");
  }
  if (!voice) {
    throw new Error("Die Stimme muss angegeben werden.");
  }
  if (!language) {
    throw new Error("Die Sprache muss angegeben werden.");
  }

  // Debugging: Konsolenausgabe zur Überprüfung der Parameter
  // console.log("*************** in generateSpeech ");
  console.log("Text:    ", text);
  console.log("Voice:   ", voice);
  console.log("Language:", language);
  console.log("Gender:  ", gender);

  // API-Aufruf an den Azure Speech Endpoint
  const response = await fetch(`${API_CONFIG.BASE_URL}/azureSpeech`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Content-Type für JSON-Daten
    },
    body: JSON.stringify({ text, voice, language, gender }), // JSON-Objekt mit Text, Stimme und Sprache
  });

  // Überprüfung der Antwort vom Server
  if (!response.ok) {
    throw new Error(`Fehler bei der Azure Speech-Generierung: ${response.statusText}`);
  }

  // Rückgabe der Audiodatei als Blob
  return response.blob();
}

/**
 * Spielt die gegebene Audiodatei ab.
 * @param {Blob} audioBlob - Die Audiodatei als Blob.
 */
export function playAudio(audioBlob) {
  // URL für die Audiodatei erstellen
  const audioUrl = URL.createObjectURL(audioBlob);
  const audio = new Audio(audioUrl);

  // Audiodatei abspielen
  audio.play();
}

/**
 * Sendet Text satzweise an den Azure Speech Endpoint und gibt die generierten Audiodateien zurück.
 * @param {string} text - Der Text, der gesprochen werden soll.
 * @param {string} voice - Die Stimme, die verwendet werden soll (z. B. "en-US-GuyNeural").
 * @param {string} language - Die Sprache, die verwendet werden soll (z. B. "de-DE").
 * @returns {Promise<void>} - Eine Promise, die aufgelöst wird, wenn alle Sätze gesprochen wurden.
 */
export async function generateSpeechBySentence(text, voice, language) {
  // Text in Sätze aufteilen
  const sentences = splitTextIntoSentences(text);
  console.log('Sätze für die Sprachausgabe:', sentences);

  // Jeden Satz separat verarbeiten
  for (const sentence of sentences) {
    try {
      const audioBlob = await generateSpeech(sentence, voice, language);
      await playAudioWithDelay(audioBlob); // Satz abspielen und warten
    } catch (error) {
      console.error('Fehler bei der Satzverarbeitung:', error);
    }
  }
}

/**
 * Spielt die Audiodatei ab und wartet, bis sie fertig ist.
 * @param {Blob} audioBlob - Die Audiodatei als Blob.
 * @returns {Promise<void>} - Eine Promise, die aufgelöst wird, wenn die Wiedergabe abgeschlossen ist.
 */
export function playAudioWithDelay(audioBlob) {
  return new Promise((resolve) => {
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);

    // Auf Ende der Wiedergabe warten
    audio.addEventListener('ended', resolve);
    audio.play();
  });
}

/**
 * Teilt einen Text in Sätze auf.
 * @param {string} text - Der zu verarbeitende Text.
 * @returns {string[]} - Eine Liste von Sätzen.
 */
function splitTextIntoSentences(text) {
  // Aufteilung des Textes anhand von Satzzeichen
  return text.match(/[^.!?]+[.!?]*/g) || [];
}
