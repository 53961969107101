import React, { createContext, useState, useEffect } from 'react';
import API_CONFIG 				from '../config.js';

export const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
  const [avatars, setAvatars] = useState([]);

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await fetch(`${API_CONFIG.BASE_URL}/getAvatars`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAvatars(data); // Avatare in den Zustand speichern
        } else {
          console.error('Failed to fetch avatars:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching avatars:', error);
      }
    };

    fetchAvatars();
  }, []);

  return (
    <AvatarContext.Provider value={avatars}>
      {children}
    </AvatarContext.Provider>
  );
};
