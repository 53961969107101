import React from 'react';

import './index.css';

import ReactDOM 			from 'react-dom/client';
import App 					from './App.js';
import reportWebVitals 		from './reportWebVitals.js';
import { LanguageProvider } from './context/LanguageContext.js';
import './i18n'; 
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
   <BrowserRouter>
    <LanguageProvider>  {/* 🆕 LanguageProvider um App herum */}
      <App />
    </LanguageProvider>
	</BrowserRouter>
  </React.StrictMode>
);

// Performance-Logging (optional)
reportWebVitals();
