import React from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import firebaseApp from '../firebaseConfig.js';
import googleIcon from '../assets/icons/google-icon.png';
import API_CONFIG from '../config.js';
import Cookies from 'js-cookie';

const GoogleLogin = ({ setUser, selectedLanguage, translations }) => {

	const handleGoogleLogin = async () => {
	  const auth = getAuth(firebaseApp);

	  try {
	    await auth.signOut();

	    const provider = new GoogleAuthProvider();
	    const result = await signInWithPopup(auth, provider);

	    const user = result.user;
	    const displayName = user.displayName || 'User';
	    const firstName = displayName.split(' ')[0] || 'User';
	    const email = user.email;

	    console.log('✅ Google login successful:', user);

	    // **Zuerst versuchen, Benutzer zu laden**
	    let fetchedUser = await fetchUserData(email);

	    if (fetchedUser) {
	      console.log("✅ Benutzer gefunden, setze ihn:", fetchedUser);
	      //setUser(fetchedUser);
	      //return;
	    } else {}

	    	// ❗ Falls kein Benutzer existiert, erst dann registrieren
	    	console.warn("⚠️ Benutzer nicht gefunden, registriere ihn jetzt.");
	    	const backendUser = {
	      		name: displayName,
	      		email: email,
	      		password: "", // Social Logins brauchen kein Passwort
	    	};

	    	try {
	      	const registerResponse = await fetch(`${API_CONFIG.BASE_URL}/register`, {
	        	method: "POST",
	        		headers: { "Content-Type": "application/json" },
	        		body: JSON.stringify(backendUser),
	      	});

	      	if (registerResponse.ok) {
	        	console.log("✅ Benutzer erfolgreich registriert.");
	        	fetchedUser = await fetchUserData(email);
	        	//setUser(fetchedUser);
	      	} else {
	        	console.warn("⚠️ Benutzer konnte nicht registriert werden:", await registerResponse.text());
	      	}
	    	} catch (backendError) {
	      		console.error("❌ Fehler beim Registrieren des Users:", backendError);
	    	}
		

	  } catch (error) {
	    console.error('❌ Google login error:', error);
	    alert(translations[selectedLanguage]?.googleLoginFailed || 'Google login failed. Please try again.');
	  }
	};

  /**
   * ✅ Holt Benutzerinformationen vom Backend (inkl. Pläne & Zahlungen)
   */
  const fetchUserData = async (email) => {
	try {
	   const response = await fetch(`${API_CONFIG.BASE_URL}/get-user?email=${email}`);
	   if (!response.ok) {
	     throw new Error("Benutzer konnte nicht geladen werden.");
	   }
	   const userData = await response.json();
	   console.log("📌 Benutzer-Daten empfangen:", userData);

	   // ✅ Benutzer mit seinen Plänen & Zahlungen speichern
	   setUser(userData);
	   
	   // Speichere Benutzer im Cookie
	   Cookies.set("user", JSON.stringify(userData), { expires: 7 });

	   // Falls der Benutzer Pläne hat, direkt weiterleiten
	   if (userData.plans && userData.plans.length > 0) {
	     console.log("✅ Benutzer hat einen Plan, weiter zur Hauptseite.");
	     return;
	   } else {
	     console.log("⚠️ Kein Plan gefunden, bleibt auf der PricingPage.");
	   }
	   
	 } catch (error) {
	   console.error("❌ Fehler beim Abrufen des Benutzers:", error);
	 }
  };

  return (
    <button className="social-media-button" onClick={handleGoogleLogin}>
      <img src={googleIcon} alt="Google Login" className="icon-image" />
    </button>
  );
};

export default GoogleLogin;
