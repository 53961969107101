import React, { useState, useEffect } from 'react';
import './PricingPage.css';

import { useTranslation } 	from 'react-i18next';
import Cookies 				from "js-cookie";
import stripePromise 		from '../stripe'; // Stripe importieren
import API_CONFIG 			from '../config.js';

const PricingPage = ({ user }) => {
	
  const { t } 								= useTranslation();
  const [plans, setPlans] 					= useState([]);
  const [showAlert, setShowAlert] 			= useState(false);
  const [selectedMode, setSelectedMode] 	= useState("personal"); // Standard: Persönlich
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // Funktion zum Abrufen eines Query-Parameters aus der URL
  const getQueryParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  useEffect(() => {
          // ✅ Versuche, den User aus dem LocalStorage zu holen
          const storedUser = JSON.parse(localStorage.getItem("user"));
          if (storedUser) {
              setUser(storedUser);
          }
      }, []);
	  
  useEffect(() => {
    const paymentSuccess = document.cookie.includes("paymentSuccess=true");

    if (paymentSuccess) {
      console.log("✅ Zahlung erkannt. Cookie entfernen und Benutzer weiterschicken.");
      document.cookie = "paymentSuccess=; path=/; max-age=0"; // Cookie löschen
      return; // Lässt App.js weitermachen
    }
  }, []);
  
  useEffect(() => {
    const paymentId = getQueryParam("session_id");

    if (paymentId) {
      console.log("✅ Zahlung erfolgreich erkannt:", paymentId);

      const email = Cookies.get("userEmail");
      const plan = Cookies.get("selectedPlan");

      if (!email || !plan) {
        console.warn("⚠️ Kein Benutzer oder Plan gefunden.");
        return;
      }

      const paymentData = {
        email,
        plan,
        paymentId,
        paymentDate: new Date().toISOString(),
      };
    }
  }, []);

  useEffect(() => {
    const availablePlans = [
      "free",
      "early",
      "plus",
      "pro"
    ].map(planKey => ({
      key: 			planKey,
      name: 		t(`pricing.plans.${planKey}.name`),
      price: 		t(`pricing.plans.${planKey}.price`),
      description: 	t(`pricing.plans.${planKey}.description`),
      features: 	t(`pricing.plans.${planKey}.features`, { returnObjects: true }) || [],
      button: 		t(`pricing.plans.${planKey}.button`, { defaultValue: "" }),
      isActive: 	true //planKey === "early" // Nur "Early Adopters" ist aktiv
    }));

    setPlans(availablePlans);
  }, [t]);

  const handleDisabledClick = (event) => {
    event.stopPropagation	();
    setShowAlert			(true);
    setTimeout(() => setShowAlert(false), 3000);
  };

  // ========== START PAYMENT (STRIPE) =========================================================
  
  const handlePayment = async (planKey) => {
    Cookies.set("selectedPlan", planKey, { expires: 1 });

    const stripe = await stripePromise;

    try {
      // console.log("****** URL PAYMENT: ", `${API_CONFIG.BASE_URL}/create-checkout-session`);

      const response = await fetch(`${API_CONFIG.BASE_URL}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        
		// DEBUG
		//body: JSON.stringify({ plan:'test', email: user.email }),
		body: JSON.stringify({ plan: planKey, email: user.email }),		
		
      });

      if (!response.ok) {
        throw new Error("Fehler bei der Zahlungssitzung");
      }

      const session = await response.json();
      await stripe.redirectToCheckout({ sessionId: session.id });

    } catch (error) {
      console.error('⚠️ Zahlung fehlgeschlagen:', error);
      alert('⚠️ Zahlung fehlgeschlagen. Bitte versuche es erneut.');
    }
  };

  return (
    <div className="pricing-page-container">
      <h1>{t('pricing.title')}</h1>

      {/* Erfolgreiche Zahlung anzeigen */}
      {paymentSuccess ? (
        <div className="payment-success">
          <h2>✅ Zahlung erfolgreich!</h2>
          <p>Vielen Dank für deinen Kauf. Dein Plan wurde aktiviert.</p>
        </div>
      ) : (
        <>
          {/* Toggle-Buttons für Persönlich & Geschäftlich */}
          <div className="pricing-toggle">
            <button
              className={`toggle-button ${selectedMode === "personal" ? "active" : ""}`}
              onClick={() => setSelectedMode("personal")}
            >
              {t('pricing.toggle.personal')}
            </button>
            <button
              className={`toggle-button ${selectedMode === "business" ? "active" : ""}`}
              onClick={() => setSelectedMode("business")}
            >
              {t('pricing.toggle.business')}
            </button>
          </div>

          <div className="pricing-options">
            {plans.map((plan) => (
              <div key={plan.key} className={`pricing-card ${plan.key}`}>
                <h2>{plan.name}</h2>
                <p className="price">{plan.price === "-" ? plan.price : plan.price}</p>
                
                {plan.description && <p>{plan.description}</p>}

                <ul>
                  {plan.features.length > 0 ? (
                    plan.features.map((feature, index) => (
                      <li key={index}>
                        <span className="green-check">✔️</span> {feature}
                      </li>
                    ))
                  ) : (
                    <li>⚠ Keine Features verfügbar</li>
                  )}
                </ul>

                <div className="button-container">
                  {plan.button && (
                    <div className="button-wrapper">
                      <button
                        className={`select-button ${plan.isActive ? '' : 'disabled'}`}
                        onClick={plan.isActive ? () => handlePayment(plan.key) : handleDisabledClick}
                      >
                        {plan.button}
                      </button>

                      {!plan.isActive && (
                        <div className="disabled-overlay" onClick={handleDisabledClick}></div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {showAlert && (
        <div className="alert-popup">
          Noch nicht verfügbar!
        </div>
      )}
    </div>
  );
};

export default PricingPage;
