import React, { useEffect, useState, useContext }from 'react';
import './ValuesOverlay.css';

import API_CONFIG 				from '../config.js';
import { GlobalStateContext } 		from '../context/GlobalStateContext';

const hormoneInfo = {
  CORTISOL: { max: 25, min: 5, default: 10, unit: 'µg/dl' },
  ADRENALIN: { max: 15, min: 2, default: 7, unit: 'µg/dl' },
  NORADRENALIN: { max: 15, min: 2, default: 7, unit: 'µg/dl' },
  DOPAMIN: { max: 100, min: 20, default: 50, unit: 'ng/mL' },
  SEROTONIN: { max: 120, min: 40, default: 80, unit: 'ng/mL' },
  ENDORPHIN: { max: 50, min: 10, default: 25, unit: 'ng/mL' },
  OXYTOCIN: { max: 60, min: 10, default: 30, unit: 'pg/mL' },
  ESTROGEN: { max: 80, min: 20, default: 50, unit: 'pg/mL' },
  PROGESTERONE: { max: 40, min: 10, default: 20, unit: 'ng/mL' },
  TESTOSTERONE: { max: 1000, min: 200, default: 600, unit: 'ng/dL' },
  INSULIN: { max: 50, min: 5, default: 20, unit: 'µU/mL' },
  GLUCAGON: { max: 20, min: 5, default: 10, unit: 'pg/mL' },
  THYROXIN: { max: 3, min: 0.5, default: 1.5, unit: 'µg/dL' },
  TRIIODOTHYRONINE: { max: 5, min: 1, default: 2.5, unit: 'ng/dL' },
  GROWTH_HORMONE: { max: 15, min: 3, default: 7, unit: 'ng/mL' },
  SOMATOSTATIN: { max: 10, min: 2, default: 5, unit: 'pg/mL' },
  MELATONIN: { max: 30, min: 5, default: 15, unit: 'pg/mL' },
  ALDOSTERONE: { max: 20, min: 5, default: 10, unit: 'ng/dL' },
  VASOPRESSIN: { max: 15, min: 3, default: 7, unit: 'pg/mL' },
  PARATHORMONE: { max: 30, min: 5, default: 15, unit: 'pg/mL' },
  CALCITONIN: { max: 10, min: 2, default: 5, unit: 'pg/mL' },
};

const brainStateInfo = {
  HAPPINESS: { max: 10, min: 0, default: 5, unit: '' },
  SADNESS: { max: 10, min: 0, default: 2, unit: '' },
  ANGER: { max: 10, min: 0, default: 3, unit: '' },
  FEAR: { max: 10, min: 0, default: 3, unit: '' },
  DISGUST: { max: 10, min: 0, default: 2, unit: '' },
  SURPRISE: { max: 10, min: 0, default: 5, unit: '' },
  GRATITUDE: { max: 10, min: 0, default: 6, unit: '' },
  EMBARRASSMENT: { max: 10, min: 0, default: 3, unit: '' },
  GUILT: { max: 10, min: 0, default: 3, unit: '' },
  SHAME: { max: 10, min: 0, default: 3, unit: '' },
  PRIDE: { max: 10, min: 0, default: 6, unit: '' },
  RELIEF: { max: 10, min: 0, default: 5, unit: '' },
  HOPE: { max: 10, min: 0, default: 6, unit: '' },
  CONTENTMENT: { max: 10, min: 0, default: 7, unit: '' },
  OPTIMISM: { max: 10, min: 0, default: 6, unit: '' },
  LOVE: { max: 10, min: 0, default: 8, unit: '' },
  INTEREST: { max: 10, min: 0, default: 7, unit: '' },
  EXCITEMENT: { max: 10, min: 0, default: 6, unit: '' },
  FRUSTRATION: { max: 10, min: 0, default: 4, unit: '' },
  DISAPPOINTMENT: { max: 10, min: 0, default: 3, unit: '' },
  ANXIETY: { max: 10, min: 0, default: 4, unit: '' },
  JEALOUSY: { max: 10, min: 0, default: 3, unit: '' },
  ENVY: { max: 10, min: 0, default: 3, unit: '' },
  PANIC: { max: 10, min: 0, default: 2, unit: '' },
  EMPATHY: { max: 10, min: 0, default: 7, unit: '' },
  COMPASSION: { max: 10, min: 0, default: 8, unit: '' },
  ADMIRATION: { max: 10, min: 0, default: 6, unit: '' },
  ANNOYANCE: { max: 10, min: 0, default: 3, unit: '' },
  CRITICISM: { max: 10, min: 0, default: 4, unit: '' },
  NEUTRAL: { max: 10, min: 0, default: 5, unit: '' },
  IRONY: { max: 10, min: 0, default: 5, unit: '' },
  CONFUSION: { max: 10, min: 0, default: 4, unit: '' },
  IMPATIENCE: { max: 10, min: 0, default: 3, unit: '' },
  IRRITABILITY: { max: 10, min: 0, default: 4, unit: '' },
  CONFIDENCE: { max: 10, min: 0, default: 7, unit: '' },
  AGGRESSION: { max: 10, min: 0, default: 4, unit: '' },
  ENERGY: { max: 10, min: 0, default: 6, unit: '' },
  BALANCE: { max: 10, min: 0, default: 8, unit: '' },
  LACK_OF_RECOVERY: { max: 10, min: 0, default: 3, unit: '' },
  RELAXATION: { max: 10, min: 0, default: 8, unit: '' },
  RECOVERY: { max: 10, min: 0, default: 7, unit: '' },
  CALMNESS: { max: 10, min: 0, default: 7, unit: '' },
  PAIN: { max: 10, min: 0, default: 3, unit: '' },
  HEARTBEAT: { max: 10, min: 0, default: 5, unit: '' },
  FATIGUE: { max: 10, min: 0, default: 4, unit: '' },
  COLD: { max: 10, min: 0, default: 3, unit: '' },
  WARMTH: { max: 10, min: 0, default: 7, unit: '' },
  DIZZINESS: { max: 10, min: 0, default: 3, unit: '' },
  TENSION: { max: 10, min: 0, default: 4, unit: '' },
  DISTRACTION: { max: 10, min: 0, default: 3, unit: '' },
  OVERWHELMED: { max: 10, min: 0, default: 3, unit: '' },
  LONELINESS: { max: 10, min: 0, default: 2, unit: '' },
  CONNECTEDNESS: { max: 10, min: 0, default: 7, unit: '' },
  MISUNDERSTOOD: { max: 10, min: 0, default: 3, unit: '' },
  MOTIVATION: { max: 10, min: 0, default: 6, unit: '' },
  DEMOTIVATION: { max: 10, min: 0, default: 3, unit: '' },
  HUNGER: { max: 10, min: 0, default: 5, unit: '' },
  THIRST: { max: 10, min: 0, default: 5, unit: '' },
  NOISE_SENSITIVITY: { max: 10, min: 0, default: 4, unit: '' },
  LIGHT_SENSITIVITY: { max: 10, min: 0, default: 4, unit: '' },
  SMELL: { max: 10, min: 0, default: 5, unit: '' },
  TOUCH: { max: 10, min: 0, default: 6, unit: '' },
  COMPLEXITY: { max: 10, min: 0, default: 5, unit: '' },
  PROBABILITY_ANSWER: { max: 10, min: 0, default: 6, unit: '' },
};



const renderHormoneBarChart = (data) => {
  if (!data || typeof data !== 'object') return 'No data available';

  const keys = Object.keys(data);
  return (
    <div className="bar-chart">
      {keys.map((key) => {
		
        const hormone 	= hormoneInfo[key];
        const value 	= data[key];
        if (!hormone) return null; // Ignoriere unbekannte Hormone

        const normalizedWidth 	= (value / hormone.max) * 100; // Normalisierung
        const defaultWidth 		= (hormone.default / hormone.max) * 100; // Default-Wert
        const minWidth 			= (hormone.min / hormone.max) * 100; // Minimum
        const maxWidth 			= 100; // Maximum ist immer bei 100%

        return (
          <div key={key} className="bar-item">
            <span className="bar-label">{key}</span>
            <div className="bar">
              {/* Striche für Minimum, Default und Maximum */}
              <div className="bar-marker min" style={{ left: `${minWidth}%` }}></div>
              <div className="bar-marker default" style={{ left: `${defaultWidth}%` }}></div>
              <div className="bar-marker max" style={{ left: `${maxWidth}%` }}></div>
              {/* Gefüllter Balken */}
              <div
                className="bar-fill"
                style={{ width: `${normalizedWidth}%`, backgroundColor: '#007bff' }}
              ></div>
            </div>
            <span className="bar-value">
              {value.toFixed(2)} {hormone.unit}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const parseToJSON = (data) => {
  if (!data) return {};
  return data
    .split(',')
    .map((pair) => pair.split(':'))
    .reduce((obj, [key, value]) => {
      const parsedValue = parseFloat(value.trim());
      if (!isNaN(parsedValue)) {
        obj[key.trim()] = parsedValue;
      }
      return obj;
    }, {});
};

const renderBarChart = (data) => {
  if (!data || typeof data !== 'object') return 'No data available';

  const keys = Object.keys(data);
  return (
    <div className="bar-chart">
      {keys.map((key) => {
        const brainState = brainStateInfo[key];
        const value = data[key];
        if (!brainState) return null; // Ignoriere unbekannte BrainStates

        const normalizedWidth 	= (value / brainState.max) * 100; // Normalisierung
        const defaultWidth 		= (brainState.default / brainState.max) * 100; // Default-Wert
        const minWidth 			= (brainState.min / brainState.max) * 100; // Minimum
        const maxWidth 			= 100; // Maximum ist immer bei 100%

        return (
          <div key={key} className="bar-item">
            <span className="bar-label">{key}</span>
            <div className="bar">
              {/* Striche für Minimum, Default und Maximum */}
              <div className="bar-marker min" style={{ left: `${minWidth}%` }}></div>
              <div className="bar-marker default" style={{ left: `${defaultWidth}%` }}></div>
              <div className="bar-marker max" style={{ left: `${maxWidth}%` }}></div>
              {/* Gefüllter Balken */}
              <div
                className="bar-fill"
                style={{ width: `${normalizedWidth}%`, backgroundColor: '#007bff' }}
              ></div>
            </div>
            <span className="bar-value">{value.toFixed(2)}</span> {/* Begrenzung auf zwei Nachkommastellen */}
          </div>
        );
      })}
    </div>
  );
};


			
			
const ValuesOverlay = ({ avatarName, userEmail, onClose }) => {
  const {
    brainStates,
    setBrainStates,
    hormones,
    setHormones,
    brainStatesAvatar,
    setBrainStatesAvatar,
    hormonesAvatar,
    setHormonesAvatar,
  } = useContext(GlobalStateContext);
  
	//console.log("Props in ValuesOverlay:", {
	//  brainStatesAvatar,
	//  hormonesAvatar,
	//});
	
	useEffect(() => {
	   const intervalId = setInterval(() => {
	     handleRefreshClick();
	   }, 2000);

	   return () => clearInterval(intervalId); // Aufräumen, wenn das Overlay geschlossen wird
	 }, []);
	 
	
  const handleRefreshClick = async () => {
	
	
    const fetchMetaData = async (name) => {
      try {
		console.log(`Fetching metadata for ${name}`);
        const response = await fetch(
          `${API_CONFIG.BASE_URL}/getNHSMetaData?avatarName=${name}&userEmail=${userEmail}`
        );
        if (!response.ok) throw new Error(`API call failed with status ${response.status}`);
		
		console.log(`Response status: ${response.status}`);
		const text = await response.text();
		    console.log('Response text:', text);
			
        const metaInfo = text;
        const brainStatesMatch = metaInfo.match(/\{AI:internal:nhs:states:([^\}]+)\}/);
        const hormonesMatch = metaInfo.match(/\{AI:internal:nhs:hormones:([^\}]+)\}/);
        return {
          brainStates: brainStatesMatch ? brainStatesMatch[1] : null,
          hormones: hormonesMatch ? hormonesMatch[1] : null,
        };
      } catch (error) {
        console.error('Error fetching NHS Meta Data:', error);
        return { brainStates: null, hormones: null };
      }
    };

    const { brainStates: userBrainStates, hormones: userHormones } = await fetchMetaData(avatarName);
    if (userBrainStates) setBrainStates(userBrainStates);
    if (userHormones) setHormones(userHormones);

    const { brainStates: avatarBrainStates, hormones: avatarHormones } = await fetchMetaData(`avatar.${avatarName}` );
    if (avatarBrainStates){
		// console.log("Avatar brain states updated:", avatarBrainStates);
		setBrainStatesAvatar(avatarBrainStates);
	}
    if (avatarHormones) setHormonesAvatar(avatarHormones);
  };

  return (
    <div className="values-overlay">
      <h1>Brain States & Hormones</h1>

      <h2>- User -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Brain States&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- AI -</h2>
      <div className="values-overlay-content horizontal-container">
        <div className="chart-container">
          <div className="chart-section">
            {brainStates ? renderBarChart(parseToJSON(brainStates)) : 'No data available'}
          </div>
          <div className="chart-section">
            {brainStatesAvatar ? renderBarChart(parseToJSON(brainStatesAvatar)) : 'No data available'}
          </div>
        </div>
      </div>

      <h2>- User -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hormones&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- AI -</h2>
      <div className="values-overlay-content horizontal-container">
        <div className="chart-container">
          <div className="chart-section">
            {hormones ? renderHormoneBarChart(parseToJSON(hormones)) : 'No data available'}
          </div>
          <div className="chart-section">
            {hormonesAvatar ? renderHormoneBarChart(parseToJSON(hormonesAvatar)) : 'No data available'}
          </div>
        </div>
      </div>

      <div className="button-section">
        <button className="refresh-button" onClick={handleRefreshClick}>
          Refresh Data
        </button>
        <button className="close-overlay-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ValuesOverlay;