import React, { useEffect, useRef } from 'react';
import { handleSpeak } from './Avatar'; // Passe den Pfad entsprechend an

import './ChatWindow.css';


const ChatWindow = ({ messages, withVoice, selectedAvatar, isLoading }) => {
  const chatEndRef = useRef(null); // Ref für das Ende des Chat-Fensters
  const layoutTriggerRef = useRef(null);
  
  useEffect(() => {
    const ensureLayout = () => {
      if (chatEndRef.current) {
        const chatContainer = chatEndRef.current.parentElement;

        // Überprüfen, ob ein Scrollbalken vorhanden ist
        const isScrollable = chatContainer.scrollHeight > chatContainer.clientHeight;

        if (isScrollable) {
          // Scrollen zum Ende des Chat-Fensters
          chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    // Sicherstellen, dass das Layout korrekt ist
    requestAnimationFrame(() => {
      ensureLayout(); // Nach dem ersten Render überprüfen
    });

    // Fallback für langsame Layout-Berechnungen
    const layoutTimeout = setTimeout(() => {
      ensureLayout(); // Nach einer kurzen Verzögerung erneut prüfen
    }, 100);

    return () => clearTimeout(layoutTimeout); // Timeout bereinigen, wenn der Effekt neu ausgeführt wird
  }, [messages]);
  
  
  useEffect(() => {
    const speakLastMessage = async () => {
      if (withVoice && messages.length > 0) {
        const lastMessage = messages[messages.length - 1];
        try {
          console.log('Generating speech for:', lastMessage.text);
          const audioBlob = await generateSpeech(
            lastMessage.text,
            selectedAvatar.voice || 'en-US-GuyNeural',
            selectedAvatar.tone || 'neutral'
          );
          playAudio(audioBlob);
        } catch (error) {
          console.error('Error in speech synthesis:', error);
        }
      }
    };

    speakLastMessage();
  }, [messages, withVoice, selectedAvatar]);


	
  useEffect(() => {
    const triggerGlobalLayout = () => {
      // Simuliere ein Resize-Event, um das Layout neu zu berechnen
      window.dispatchEvent(new Event('resize'));
    };
    // Layout aktualisieren, wenn sich der Fortschrittsbalkenstatus ändert
    if (!isLoading) {
      triggerGlobalLayout();
    }
  }, [isLoading]);
	
  return (
    <div className="chat-output">
	  {messages.map((message, index) => {
	        const cleanedText = message?.text ? message.text.replace(/^:\n/, '') : '';			
	        return (
	          <p key={index}> <strong>{message.sender && `${message.sender}:`}</strong> {cleanedText}</p>
	        );
	      })}
		  <div ref={layoutTriggerRef} style={{ visibility: 'hidden', height: '1px' }} />
      {/* Platzhalter für das Scrollen */}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ChatWindow;
