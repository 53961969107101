// ./components/Consent.js

import React, { useState } from 'react';
import API_CONFIG from '../config'; // Stelle sicher, dass API_CONFIG.BASE_URL korrekt definiert ist
import { useTranslation } from 'react-i18next';


const Consent = ({ user, setUser, onClose }) => {
	
  const { t } 					= useTranslation();
  const [checked, setChecked] 	= useState(false);
  const [loading, setLoading] 	= useState(false);
  const [error, setError] 		= useState(null);

  // Wird aufgerufen, wenn der Benutzer den Checkbox-Status ändert
  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  // Wird aufgerufen, wenn der "Weiter" Button geklickt wird
  const handleAccept = async () => {
    // Sicherheitshalber prüfen, ob die Checkbox aktiviert ist
    if (!checked) return;
    setLoading(true);
    setError(null);
    try {
      console.log("[Consent].handleAccept(): email: ", user.email);
      // Sende POST-Request an den API-Endpunkt, um den Consent zu setzen
      const response = await fetch(`${API_CONFIG.BASE_URL}/setConsent`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.email, consent: true })
      });

      if (!response.ok) {
        throw new Error('Fehler beim Setzen der Zustimmung.');
      }
      
	  // Hole das aktualisierte User-Objekt vom Server
	      const updatedUser = await response.json();
	      // Aktualisiere den User-State, damit der neue Consent-Status übernommen wird
	      setUser(updatedUser);
		  
      if (onClose) {
        onClose();
      }
    } catch (err) {
      console.error('Consent Error:', err);
      setError('Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
    }
    setLoading(false);
  };

  // Bei Klick auf "Zurück zur Hauptseite" leiten wir z. B. zur Startseite weiter
  const handleBack = () => {
    window.location.href = '/';
  };

  return (
      <div className="consent-overlay" style={styles.overlay}>
        <div className="consent-modal" style={styles.modal}>
          <h2>{t('consent.title')}</h2>
          <p>{t('consent.description')}</p>
          <div style={styles.checkboxContainer}>
            <input
              type="checkbox"
              id="consentCheckbox"
              checked={checked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="consentCheckbox" style={styles.label}>
              {t('consent.checkboxLabel')}
            </label>
          </div>
          {error && <p style={styles.error}>{error}</p>}
          <div style={styles.buttonGroup}>
            <button
              onClick={handleAccept}
              disabled={!checked || loading}
              style={{ ...styles.button, opacity: (!checked || loading) ? 0.6 : 1 }}
            >
              {loading ? t('consent.loading') : t('consent.continue')}
            </button>
            <button onClick={handleBack} style={styles.buttonSecondary}>
              {t('consent.backHome')}
            </button>
          </div>
        </div>
      </div>
    );
};

// Beispielhafte Inline-CSS-Stile; alternativ kannst du auch eine separate CSS-Datei verwenden.
const styles = {
  overlay: {
    position: 'fixed',
    top: 0, left: 0, right: 0, bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  },
  modal: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    textAlign: 'center'
  },
  checkboxContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: '0.5rem'
  },
  error: {
    color: 'red',
    marginTop: '0.5rem'
  },
  buttonGroup: {
    marginTop: '1.5rem',
    display: 'flex',
    justifyContent: 'space-around'
  },
  button: {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    cursor: 'pointer'
  },
  buttonSecondary: {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
    cursor: 'pointer',
    backgroundColor: '#ddd',
    border: 'none'
  }
};

export default Consent;
