import React, { useState } from 'react';
import API_CONFIG 				from '../config.js';

const TrialForm = ({ onSubmit, translations, selectedLanguage, togglePopup }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name.trim()) {
      errors.name = translations[selectedLanguage]?.invalidName || 'Name is required.';
    }

    if (!email.trim() || !emailRegex.test(email)) {
      errors.email = translations[selectedLanguage]?.invalidEmail || 'Valid email is required.';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) return;

    const payload = {
      name,
      email,
    };

    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}/trial`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error:', errorMessage);
        togglePopup(`Error: ${errorMessage}`);
        return;
      }

      const result = await response.text();
      console.log('Trial response:', result);
      togglePopup(
        translations[selectedLanguage]?.trialStarted || 'Your trial has started successfully!'
      );
      onSubmit(payload);
    } catch (error) {
      console.error('Error during trial:', error);
      togglePopup('An error occurred while starting your trial.');
    }
  };

  return (
    <div className="trial-form">
      <h2>{translations[selectedLanguage]?.startTrial || 'Start Your Trial'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <input
            type="text"
            placeholder={translations[selectedLanguage]?.name || 'Name'}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p className="error-message">{errors.name}</p>}
        </div>
        <div className="input-field">
          <input
            type="email"
            placeholder={translations[selectedLanguage]?.email || 'Email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="error-message">{errors.email}</p>}
        </div>
        <button type="submit" className="trial-submit-button">
          {translations[selectedLanguage]?.submitTrial || 'Start Trial'}
        </button>
      </form>
    </div>
  );
};

export default TrialForm;
