import React, { useState, useEffect, useContext } from 'react';
import './AvatarSelection.css';
import { AvatarContext } from '../context/AvatarContext.js';
import API_BASE_URL from '../config.js';
import Cookies from 'js-cookie';
import { LanguageContext } from '../context/LanguageContext';

const translations = {
  'en-US': {
    selectAvatar: 'Select an Avatar',
    select: 'Select',
    info: 'Info',
    profession: 'Profession',
    region: 'Region',
    languages: 'Languages',
    close: 'Close',
    professions: {
      'Creator of the first AI with emotions': 'Creator of the first AI with emotions',
      'Empathic Friend': 'Empathic Friend',
      'Trusted Friend': 'Trusted Friend',
      'Software Engineer': 'Software Engineer',
      'Pediatrician': 'Pediatrician',
      'Psychotherapist': 'Psychotherapist',
      'Civil Engineer': 'Civil Engineer',
      'Doctor': 'Doctor',
      'Data Scientist': 'Data Scientist',
      'Architect': 'Architect',
      'Teacher': 'Teacher',
      'Psychologist': 'Psychologist',
      'Lawyer': 'Lawyer',
      'Journalist': 'Journalist',
      'Entrepreneur': 'Entrepreneur',
    },
  },
  'de-DE': {
    selectAvatar: 'Wähle einen Avatar',
    select: 'Auswählen',
    info: 'Info',
    profession: 'Beruf',
    region: 'Region',
    languages: 'Sprachen',
    close: 'Schließen',
    professions: {

      'Software Engineer': 'Software-Ingenieur',
      'Pediatrician': 'Kinderarzt',
      'Psychotherapist': 'Psychotherapeut',
      'Civil Engineer': 'Bauingenieur',
      'Doctor': 'Arzt',
      'Data Scientist': 'Datenwissenschaftler',
      'Architect': 'Architekt',
      'Teacher': 'Lehrer',
      'Psychologist': 'Psychologe',
      'Lawyer': 'Rechtsanwalt',
      'Journalist': 'Journalist',
      'Entrepreneur': 'Unternehmer',
    },
  },
  'fr-FR': {
    selectAvatar: 'Sélectionnez un avatar',
    select: 'Sélectionner',
    info: 'Info',
    profession: 'Profession',
    region: 'Région',
    languages: 'Langues',
    close: 'Fermer',
    professions: {
      'Creator of the first AI with emotions': "Créateur de la première IA avec des émotions",
      'Empathic Friend': "Ami empathique",
      'Trusted Friend': "Ami de confiance",
      'Software Engineer': 'Ingénieur logiciel',
      'Pediatrician': 'Pédiatre',
      'Psychotherapist': 'Psychothérapeute',
      'Civil Engineer': 'Ingénieur civil',
      'Doctor': 'Médecin',
      'Data Scientist': 'Scientifique des données',
      'Architect': 'Architecte',
      'Teacher': 'Enseignant',
      'Psychologist': 'Psychologue',
      'Lawyer': 'Avocat',
      'Journalist': 'Journaliste',
      'Entrepreneur': 'Entrepreneur',
    },
  },
  'es-ES': {
    selectAvatar: 'Selecciona un avatar',
    select: 'Seleccionar',
    info: 'Información',
    profession: 'Profesión',
    region: 'Región',
    languages: 'Idiomas',
    close: 'Cerrar',
    professions: {
      'Creator of the first AI with emotions': "Creador de la primera IA con emociones",
      'Empathic Friend': "Amigo empático",
      'Trusted Friend': "Amigo de confianza",
      'Software Engineer': 'Ingeniero de software',
      'Pediatrician': 'Pediatra',
      'Psychotherapist': 'Psicoterapeuta',
      'Civil Engineer': 'Ingeniero civil',
      'Doctor': 'Médico',
      'Data Scientist': 'Científico de datos',
      'Architect': 'Arquitecto',
      'Teacher': 'Profesor',
      'Psychologist': 'Psicólogo',
      'Lawyer': 'Abogado',
      'Journalist': 'Periodista',
      'Entrepreneur': 'Empresario',
    },
  },
  'it-IT': {
    selectAvatar: 'Seleziona un avatar',
    select: 'Seleziona',
    info: 'Info',
    profession: 'Professione',
    region: 'Regione',
    languages: 'Lingue',
    close: 'Chiudi',
    professions: {
      'Creator of the first AI with emotions': "Creatore della prima IA con emozioni",
      'Empathic Friend': "Amico empatico",
      'Trusted Friend': "Amico fidato",
      'Software Engineer': 'Ingegnere software',
      'Pediatrician': 'Pediatra',
      'Psychotherapist': 'Psicoterapeuta',
      'Civil Engineer': 'Ingegnere civile',
      'Doctor': 'Medico',
      'Data Scientist': 'Data Scientist',
      'Architect': 'Architetto',
      'Teacher': 'Insegnante',
      'Psychologist': 'Psicologo',
      'Lawyer': 'Avvocato',
      'Journalist': 'Giornalista',
      'Entrepreneur': 'Imprenditore',
    },
  },
  'zh-CN': {
    selectAvatar: '选择一个头像',
    select: '选择',
    info: '信息',
    profession: '职业',
    region: '地区',
    languages: '语言',
    close: '关闭',
    professions: {
      'Creator of the first AI with emotions': "情感人工智能的创造者",
      'Empathic Friend': "共情的朋友",
      'Trusted Friend': "值得信赖的朋友",
      'Software Engineer': '软件工程师',
      'Pediatrician': '儿科医生',
      'Psychotherapist': '心理治疗师',
      'Civil Engineer': '土木工程师',
      'Doctor': '医生',
      'Data Scientist': '数据科学家',
      'Architect': '建筑师',
      'Teacher': '教师',
      'Psychologist': '心理学家',
      'Lawyer': '律师',
      'Journalist': '记者',
      'Entrepreneur': '企业家',
    },
  },
  'ja-JP': {
    selectAvatar: 'アバターを選択',
    select: '選択',
    info: '情報',
    profession: '職業',
    region: '地域',
    languages: '言語',
    close: '閉じる',
    professions: {
      'Creator of the first AI with emotions': "感情を持つ最初のAIの創造者",
      'Empathic Friend': "共感的な友人",
      'Trusted Friend': "信頼できる友人",
      'Software Engineer': 'ソフトウェアエンジニア',
      'Pediatrician': '小児科医',
      'Psychotherapist': '心理療法士',
      'Civil Engineer': '土木技師',
      'Doctor': '医師',
      'Data Scientist': 'データサイエンティスト',
      'Architect': '建築家',
      'Teacher': '教師',
      'Psychologist': '心理学者',
      'Lawyer': '弁護士',
      'Journalist': 'ジャーナリスト',
      'Entrepreneur': '起業家',
    },
  },
  'ar-SA': {
    selectAvatar: 'اختر صورة رمزية',
    select: 'اختيار',
    info: 'معلومات',
    profession: 'المهنة',
    region: 'المنطقة',
    languages: 'اللغات',
    close: 'إغلاق',
    professions: {
      'Creator of the first AI with emotions': "مبتكر أول ذكاء اصطناعي ذو مشاعر",
      'Empathic Friend': "صديق متعاطف",
      'Trusted Friend': "صديق موثوق",
      'Software Engineer': 'مهندس برمجيات',
      'Pediatrician': 'طبيب أطفال',
      'Psychotherapist': 'معالج نفسي',
      'Civil Engineer': 'مهندس مدني',
      'Doctor': 'طبيب',
      'Data Scientist': 'عالم بيانات',
      'Architect': 'مهندس معماري',
      'Teacher': 'معلم',
      'Psychologist': 'طبيب نفسي',
      'Lawyer': 'محامٍ',
      'Journalist': 'صحفي',
      'Entrepreneur': 'رائد أعمال',
    },
  },
};


const calculateAge = (birthDate) => {
  const birthYear = new Date(birthDate).getFullYear();
  const currentYear = new Date().getFullYear();
  return currentYear - birthYear;
};

const AvatarSelection = ({ setSelectedAvatar, referrer }) => {
	
  const [showAvatarInfo, setShowAvatarInfo] 	= useState(null);
  const avatars 								= useContext(AvatarContext);
  const { language } 							= useContext(LanguageContext); // Sprachwert aus dem Context holen
  const localizedText 							= translations[language] || translations['en-US'];

  console.log("*********** Language: ", language);
  console.log("*********** localizedText.profession: ", localizedText.profession);
  
  useEffect(() => {
    if (referrer === 'ramin.life') {
      setSelectedAvatar(avatars[3]);
    }
  }, [referrer, setSelectedAvatar, avatars]);

  if (referrer === 'ramin.life') {
    return null;
  }

  const handleSelectAvatar = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const closeModal = () => {
    setShowAvatarInfo(null);
  };

  const getLocalizedProfession = (profession) => {
    if (localizedText.professions && localizedText.professions[profession]) {	
		console.log("********** localizedText.professions[profession]: ", localizedText.professions[profession])
      return localizedText.professions[profession];
    }
    return profession || 'N/A';
  };

  return (
    <div className="avatar-selection-container">
	
	  
      {!showAvatarInfo ? (
        <>
          <h2>{localizedText.selectAvatar}</h2>
          <div className="avatar-grid">
            {avatars.map((avatar) => (
              <div key={avatar.name} className="avatar-card">
                <img src={`/assets/${avatar.image}`} alt={avatar.name} />
                <h3>{avatar.name}</h3>
                <h4>{getLocalizedProfession(avatar.profession)}</h4>
                <button onClick={() => handleSelectAvatar(avatar)}>
                  {localizedText.select}
                </button>
                <button onClick={() => setShowAvatarInfo(avatar)}>
                  {localizedText.info}
                </button>
              </div>
            ))}
          </div>
        </>
      ) : (
		
		
		
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={`/assets/${showAvatarInfo.image}`} alt={showAvatarInfo.name} className="avatar-info-image" />
            <h2>{showAvatarInfo.name}</h2>
            <div className="modal-description">
              <p><strong>{localizedText.profession}:</strong> {getLocalizedProfession(showAvatarInfo.profession)}</p>
              <p><strong>{localizedText.region}:</strong> {showAvatarInfo.country + " - " + showAvatarInfo.zipCode}</p>
              <p><strong>{localizedText.languages}:</strong> {Array.isArray(showAvatarInfo.languages) ? showAvatarInfo.languages.join(', ') : showAvatarInfo.language}</p>
              <p><strong>Age:</strong> {calculateAge(showAvatarInfo.birthDate)} years</p>
              <p><strong>Description:</strong> {Array.isArray(showAvatarInfo.hobbies) ? showAvatarInfo.hobbies.join(', ') : showAvatarInfo.hobbies}</p>
            </div>
            <button className="close-button" onClick={closeModal}>
              {localizedText.close}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarSelection;
