import React, { useState } from "react";
import Cookies from "js-cookie";
import translations from "./translations.js"; // Import translations
import "./Login.css";
import API_CONFIG from "../config.js";

const ManualLogin = ({ setUser, selectedLanguage, togglePopup, translations, API_BASE_URL, onClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [isRegistering, setIsRegistering] = useState(false);

  const validateInputs = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      errors.email = translations[selectedLanguage]?.invalidEmail || "Invalid email address";
    }

    if (isRegistering) {
      if (!name.trim()) {
        errors.name = translations[selectedLanguage]?.invalidName || "Name is required";
      }

      if (password !== confirmPassword) {
        errors.confirmPassword =
          translations[selectedLanguage]?.passwordsDoNotMatch || "Passwords do not match";
      }
    }

    if (!password) {
      errors.password = translations[selectedLanguage]?.passwordRequired || "Password is required";
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /**
   * ✅ Holt Benutzerinformationen vom Backend (inkl. Pläne & Zahlungen)
   */
  const fetchUserData = async (email) => {
    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}/get-user?email=${email}`);
      if (!response.ok) {
        throw new Error("Benutzer konnte nicht geladen werden.");
      }
      const userData = await response.json();
      console.log("📌 Benutzer-Daten empfangen:", userData);

      // ✅ Benutzer mit seinen Plänen & Zahlungen speichern
      setUser(userData);
      
      // Speichere Benutzer im Cookie
      Cookies.set("user", JSON.stringify(userData), { expires: 7 });

      // Falls der Benutzer Pläne hat, direkt weiterleiten
      if (userData.plans && userData.plans.length > 0) {
        console.log("✅ Benutzer hat einen Plan, weiter zur Hauptseite.");
        return;
      } else {
        console.log("⚠️ Kein Plan gefunden, bleibt auf der PricingPage.");
      }
      
    } catch (error) {
      console.error("❌ Fehler beim Abrufen des Benutzers:", error);
    }
  };

  // ========== REGISTER ================================================
  
  const handleRegister = async () => {
    console.log("********** URL = ", `${API_CONFIG.BASE_URL}/register`);

    if (!validateInputs()) {
      console.log("********** !!! !validateInputs()");
      return;
    }

    console.log("Request Body:", {
      name,
      email,
      password,
      languages: [selectedLanguage],
    });

    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          password,
          languages: [selectedLanguage],
        }),
      });

      if (response.ok) {
        console.log("✅ Registrierung erfolgreich!");

        // Nach erfolgreicher Registrierung direkt einloggen
        fetchUserData(email);
      } else {
        const errorText = await response.text();
        togglePopup(errorText || "Registration failed");
      }
    } catch (error) {
      console.error("❌ Fehler bei der Registrierung:", error);
    }
  };

  // ============= LOGIN ======================================================
  
  const handleLogin = async () => {
    if (!validateInputs()) return;

    try {
      const response = await fetch(`${API_CONFIG.BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        console.log("✅ Login erfolgreich!");

        // Nach erfolgreichem Login Benutzerinformationen laden
        fetchUserData(email);
      } else {
        const errorText = await response.text();
        togglePopup(errorText || "Login failed");
      }
    } catch (error) {
      console.error("❌ Fehler beim Login:", error);
    }
  };

  // ======== UI ============================================================================
  
  return (
    <div className="manual-login-form">	  
      
       <button className="close-button-2" onClick={onClose}> 
          ✖
        </button>

      {/* ========== EMAIL ================================================== */}
      <div className="input-field-email">
        <input
          type="email"
          placeholder={translations[selectedLanguage]?.email || "Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={fieldErrors.email ? "input-error" : ""}
        />
        {fieldErrors.email && (
          <span className="error-icon" onClick={() => togglePopup(fieldErrors.email)}> ❌ </span>
        )}
      </div>

      {/* ========== NAME (nur für Registrierung) ================================================== */}
      {isRegistering && (
        <div className="input-field-name">
          <input
            type="text"
            placeholder={translations[selectedLanguage]?.name || "Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={fieldErrors.name ? "input-error" : ""}
          />
          {fieldErrors.name && (
            <span className="error-icon" onClick={() => togglePopup(fieldErrors.name)}> ❌ </span>
          )}
        </div>
      )}

      {/* ========== PASSWORD ================================================== */}
      <div className="input-field-password">
        <input
          type="password"
          placeholder={translations[selectedLanguage]?.password || "Password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={fieldErrors.password ? "input-error" : ""}
        />
        {fieldErrors.password && (
          <span className="error-icon" onClick={() => togglePopup(fieldErrors.password)}> ❌ </span>
        )}
      </div>

      {/* ========== PASSWORD CONFIRMATION (nur für Registrierung) ================================================== */}
      {isRegistering && (
        <div className="input-field-password-confirmation">
          <input
            type="password"
            placeholder={translations[selectedLanguage]?.confirmPassword || "Confirm Password"}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={fieldErrors.confirmPassword ? "input-error" : ""}
          />
          {fieldErrors.confirmPassword && (
            <span className="error-icon" onClick={() => togglePopup(fieldErrors.confirmPassword)}> ❌ </span>
          )}
        </div>
      )}

      {/* ========== BUTTONS ================================================== */}
      <div className="button-group">
        {isRegistering ? (
          <>
            <button className="register-button" onClick={handleRegister}>Register</button>
            <button className="back-to-login-button" onClick={() => setIsRegistering(false)}>Back to Login</button>
          </>
        ) : (
          <>
            <button className="login-button" onClick={handleLogin}>Login</button>
            <button className="register-button" onClick={() => setIsRegistering(true)}>Register</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ManualLogin;
