import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import i18n from '../i18n'; // 💡 Importiere `i18n`, um die Sprache dort zu ändern

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const defaultLanguage = 'en-US';

  const getInitialLanguage = () => {
    const cookieLanguage = Cookies.get('language');
    if (cookieLanguage) {
      return cookieLanguage;
    }
    const browserLanguage = navigator.language || navigator.userLanguage;
    console.log('🌐 [LanguageContext] Browsersprache erkannt:', browserLanguage);
    return browserLanguage || defaultLanguage;
  };

  const [language, setLanguage] = useState(getInitialLanguage());

  const changeLanguage = (newLang) => {
    setLanguage(newLang);
    Cookies.set('language', newLang, { expires: 7, sameSite: 'Lax' });
    i18n.changeLanguage(newLang); // 💡 Synchronisiert `i18n` mit `LanguageContext`
    console.log('🌍 [LanguageContext] Sprache geändert auf:', newLang);
  };

  useEffect(() => {
    // Synchronisiere Cookie und State, falls der Cookie außerhalb der App geändert wurde
    const checkCookie = () => {
      const cookieLang = Cookies.get('language');
      if (cookieLang && cookieLang !== language) {
        console.log('🔄 [LanguageContext] Sprache aus Cookie aktualisiert:', cookieLang);
        setLanguage(cookieLang);
        i18n.changeLanguage(cookieLang); // 💡 Falls sich das Cookie ändert, auch `i18n` aktualisieren
      }
    };

    const interval = setInterval(checkCookie, 1000);
    return () => clearInterval(interval);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
