const translations = {
	'en-US': {
	    welcome: 'Welcome to MyPal',
	    login: 'Email Login',
	    register: 'Register',
	    backToLogin: 'Back to Login',
	    email: 'Email',
	    name: 'Name',
	    password: 'Password',
	    confirmPassword: 'Confirm Password',
	    passwordsDoNotMatch: 'Passwords do not match',
	    invalidEmail: 'Please enter a valid email address',
	    invalidName: 'Please enter your first and last name',
	    wrongPassword: 'Wrong password. Attempts left: ',
	    blocked: 'Too many attempts. Application is locked.',
	    startTrial: 'Start Your 14-Day Trial',
	    submitTrial: 'Start Trial',
	    trialStarted: 'Your 14-day trial has started. Enjoy exploring the app!',
	    loginSuccessful: 'Login successful!',
	    registrationSuccessful: 'Registration successful!',
	    missingFields: 'Email and password are required',

	    // Menu
	    menu_home: 'Home',
	    menu_research: 'Research',
	    menu_products: 'Products',
	    menu_safety: 'Safety',
	    menu_company: 'Company',
	    menu_donation: 'Donation',

	    // Cookie Consent
	    cookie_text: 'We use cookies to enhance your experience.',
	    cookie_policy_link: 'View our cookie policy',
	    accept_button: 'Accept All',
	    reject_button: 'Reject Non-Essential',

	    // Features Section
	    feature_no_subscription: 'No Subscription',
	    feature_payg: 'Pay-as-you-go with initial top-up of 10 $',
	    feature_avatars: '12 individual avatars',
	    feature_image_gen: 'Image Generation',
	    feature_news: 'All avatars have access to the latest news',
	    feature_speech: 'Speech output and recognition',
	    feature_learning: 'All Avatars learn during chats & adapt'
	  },

	  'de-DE': {
	    welcome: 'Willkommen bei MyPal',
	    login: 'E-Mail Anmeldung',
	    register: 'Registrieren',
	    backToLogin: 'Zurück zur Anmeldung',
	    email: 'E-Mail',
	    name: 'Name',
	    password: 'Passwort',
	    confirmPassword: 'Passwort wiederholen',
	    passwordsDoNotMatch: 'Passwörter stimmen nicht überein',
	    invalidEmail: 'Bitte eine gültige E-Mail-Adresse eingeben',
	    invalidName: 'Bitte Vor- und Nachnamen eingeben',
	    wrongPassword: 'Falsches Passwort. Verbleibende Versuche: ',
	    blocked: 'Zu viele Versuche. Anwendung ist gesperrt.',
	    startTrial: 'Starte deine 14-tägige Testversion',
	    submitTrial: 'Testversion starten',
	    trialStarted: 'Ihre 14-tägige Testversion wurde gestartet. Viel Spaß!',
	    loginSuccessful: 'Anmeldung erfolgreich!',
	    registrationSuccessful: 'Registrierung erfolgreich!',
	    missingFields: 'E-Mail und Passwort sind erforderlich',

	    // Menu
	    menu_home: 'Startseite',
	    menu_research: 'Forschung',
	    menu_products: 'Produkte',
	    menu_safety: 'Sicherheit',
	    menu_company: 'Unternehmen',
	    menu_donation: 'Spende',

	    // Cookie Consent
	    cookie_text: 'Wir verwenden Cookies, um Ihre Erfahrung zu verbessern.',
	    cookie_policy_link: 'Unsere Cookie-Richtlinie ansehen',
	    accept_button: 'Alle akzeptieren',
	    reject_button: 'Nur notwendige ablehnen',

	    // Features Section
	    feature_no_subscription: 'Kein Abonnement',
	    feature_payg: 'Zahlung nach Nutzung mit 10 $ Startguthaben',
	    feature_avatars: '12 individuelle Avatare',
	    feature_image_gen: 'Bildgenerierung',
	    feature_news: 'Alle Avatare haben Zugriff auf die neuesten Nachrichten',
	    feature_speech: 'Sprachausgabe und Spracherkennung',
	    feature_learning: 'Alle Avatare lernen während des Chats & passen sich an'
	  },

	  'fr-FR': {
	    welcome: 'Bienvenue sur MyPal',
	    login: 'Connexion par e-mail',
	    register: "S'inscrire",
	    backToLogin: 'Retour à la connexion',
	    email: 'E-mail',
	    name: 'Nom',
	    password: 'Mot de passe',
	    confirmPassword: 'Confirmer le mot de passe',
	    passwordsDoNotMatch: 'Les mots de passe ne correspondent pas',
	    invalidEmail: 'Veuillez entrer une adresse e-mail valide',
	    invalidName: 'Veuillez entrer votre prénom et nom',
	    wrongPassword: 'Mot de passe incorrect. Tentatives restantes : ',
	    blocked: 'Trop de tentatives. Application verrouillée.',
	    startTrial: 'Commencez votre essai de 14 jours',
	    submitTrial: "Commencer l'essai",
	    trialStarted: 'Votre essai de 14 jours a commencé. Profitez-en bien !',
	    loginSuccessful: 'Connexion réussie !',
	    registrationSuccessful: 'Inscription réussie !',
	    missingFields: 'Email et mot de passe requis',

	    // Menu
	    menu_home: 'Accueil',
	    menu_research: 'Recherche',
	    menu_products: 'Produits',
	    menu_safety: 'Sécurité',
	    menu_company: 'Entreprise',
	    menu_donation: 'Don',

	    // Cookie Consent
	    cookie_text: 'Nous utilisons des cookies pour améliorer votre expérience.',
	    cookie_policy_link: 'Voir notre politique en matière de cookies',
	    accept_button: 'Tout accepter',
	    reject_button: 'Refuser les non essentiels',

	    // Features Section
	    feature_no_subscription: "Pas d'abonnement",
	    feature_payg: 'Paiement à l’utilisation avec un crédit initial de 10 $',
	    feature_avatars: '12 avatars individuels',
	    feature_image_gen: 'Génération d’images',
	    feature_news: "Tous les avatars ont accès aux dernières actualités",
	    feature_speech: 'Sortie vocale et reconnaissance',
	    feature_learning: 'Tous les avatars apprennent et s’adaptent aux conversations'
	  },
	
	
  'es-ES': {
	welcome: 'Bienvenido a MyPal',
	    login: 'Inicio de sesión con correo electrónico',
	    register: 'Registrarse',
	    backToLogin: 'Volver al inicio de sesión',
	    email: 'Correo electrónico',
	    name: 'Nombre',
	    password: 'Contraseña',
	    confirmPassword: 'Confirmar contraseña',
	    passwordsDoNotMatch: 'Las contraseñas no coinciden',
	    invalidEmail: 'Por favor, introduce una dirección de correo válida',
	    invalidName: 'Por favor, introduce tu nombre y apellido',
	    wrongPassword: 'Contraseña incorrecta. Intentos restantes: ',
	    blocked: 'Demasiados intentos. La aplicación está bloqueada.',
	    startTrial: 'Comienza tu prueba de 14 días',
	    submitTrial: 'Iniciar prueba',
	    trialStarted: 'Tu prueba de 14 días ha comenzado. ¡Disfruta explorando la app!',
	    loginSuccessful: '¡Inicio de sesión exitoso!',
	    registrationSuccessful: '¡Registro exitoso!',
	    missingFields: 'Correo electrónico y contraseña son obligatorios',

	    // Menú
	    menu_home: 'Inicio',
	    menu_research: 'Investigación',
	    menu_products: 'Productos',
	    menu_safety: 'Seguridad',
	    menu_company: 'Empresa',
	    menu_donation: 'Donación',

	    // Consentimiento de Cookies
	    cookie_text: 'Utilizamos cookies para mejorar tu experiencia.',
	    cookie_policy_link: 'Ver nuestra política de cookies',
	    accept_button: 'Aceptar todas',
	    reject_button: 'Rechazar las no esenciales',

	    // Sección de Características
	    feature_no_subscription: 'Sin suscripción',
	    feature_payg: 'Pago por uso con recarga inicial de 10 $',
	    feature_avatars: '12 avatares individuales',
	    feature_image_gen: 'Generación de imágenes',
	    feature_news: 'Todos los avatares tienen acceso a las últimas noticias',
	    feature_speech: 'Salida y reconocimiento de voz',
	    feature_learning: 'Todos los avatares aprenden y se adaptan en las conversaciones'
  },
  'it-IT': {
	welcome: 'Benvenuto su MyPal',
	login: 'Accesso con email',
	register: 'Registrati',
	backToLogin: 'Torna al login',
	email: 'E-mail',
	name: 'Nome',
	password: 'Password',
	confirmPassword: 'Conferma Password',
	passwordsDoNotMatch: 'Le password non corrispondono',
	invalidEmail: 'Per favore, inserisci un indirizzo email valido',
	invalidName: 'Per favore, inserisci il tuo nome e cognome',
	wrongPassword: 'Password errata. Tentativi rimasti: ',
	blocked: 'Troppi tentativi. Applicazione bloccata.',
	startTrial: 'Inizia la tua prova di 14 giorni',
	submitTrial: 'Inizia prova',
	trialStarted: 'La tua prova di 14 giorni è iniziata. Buona esplorazione!',
	loginSuccessful: 'Accesso riuscito!',
	registrationSuccessful: 'Registrazione riuscita!',
	missingFields: 'Email e password sono obbligatori',

	// Menu
	menu_home: 'Home',
	menu_research: 'Ricerca',
	menu_products: 'Prodotti',
	menu_safety: 'Sicurezza',
	menu_company: 'Azienda',
	menu_donation: 'Donazione',

	// Consenso ai cookie
	cookie_text: 'Utilizziamo i cookie per migliorare la tua esperienza.',
	cookie_policy_link: 'Visualizza la nostra politica sui cookie',
	accept_button: 'Accetta tutto',
	reject_button: 'Rifiuta i non essenziali',

	// Sezione Caratteristiche
	feature_no_subscription: 'Nessun abbonamento',
	feature_payg: 'Pagamento a consumo con ricarica iniziale di 10 $',
	feature_avatars: '12 avatar individuali',
	feature_image_gen: 'Generazione di immagini',
	feature_news: 'Tutti gli avatar hanno accesso alle ultime notizie',
	feature_speech: 'Uscita vocale e riconoscimento',
	feature_learning: 'Tutti gli avatar apprendono e si adattano alle conversazioni'
  },
  
  'zh-CN': {
    welcome: '欢迎来到 MyPal',
    login: '电子邮件登录',
    register: '注册',
    backToLogin: '返回登录',
    email: '电子邮件',
    name: '名字',
    password: '密码',
    confirmPassword: '确认密码',
    passwordsDoNotMatch: '密码不匹配',
    invalidEmail: '请输入有效的电子邮件地址',
    invalidName: '请输入您的名字和姓氏',
    wrongPassword: '密码错误。剩余尝试次数：',
    blocked: '尝试次数过多。应用程序已锁定。',
    startTrial: '开始您的 14 天试用',
    submitTrial: '开始试用',
    trialStarted: '您的 14 天试用已开始。尽情探索吧！',
    loginSuccessful: '登录成功！',
    registrationSuccessful: '注册成功！',
    missingFields: '电子邮件和密码是必需的',

    // 菜单
    menu_home: '首页',
    menu_research: '研究',
    menu_products: '产品',
    menu_safety: '安全',
    menu_company: '公司',
    menu_donation: '捐赠',

    // Cookie 同意
    cookie_text: '我们使用 Cookie 来改善您的体验。',
    cookie_policy_link: '查看我们的 Cookie 政策',
    accept_button: '全部接受',
    reject_button: '拒绝非必要的',

    // 功能部分
    feature_no_subscription: '无需订阅',
    feature_payg: '按使用付费，首次充值 10 美元',
    feature_avatars: '12 个个性化虚拟形象',
    feature_image_gen: '图像生成',
    feature_news: '所有虚拟形象都可访问最新新闻',
    feature_speech: '语音输出和识别',
    feature_learning: '所有虚拟形象都会在对话中学习和适应'
  },
  
  'ja-JP': {
    welcome: 'MyPal へようこそ',
    login: 'メールログイン',
    register: '登録する',
    backToLogin: 'ログインに戻る',
    email: 'メールアドレス',
    name: '名前',
    password: 'パスワード',
    confirmPassword: 'パスワードを確認',
    passwordsDoNotMatch: 'パスワードが一致しません',
    invalidEmail: '有効なメールアドレスを入力してください',
    invalidName: '名前と苗字を入力してください',
    wrongPassword: 'パスワードが間違っています。残りの試行回数: ',
    blocked: '試行回数が多すぎます。アプリケーションがロックされています。',
    startTrial: '14日間のトライアルを開始する',
    submitTrial: 'トライアルを開始',
    trialStarted: '14日間のトライアルが開始されました。お楽しみください！',
    loginSuccessful: 'ログイン成功！',
    registrationSuccessful: '登録成功！',
    missingFields: 'メールアドレスとパスワードは必須です',

    // メニュー
    menu_home: 'ホーム',
    menu_research: '研究',
    menu_products: '製品',
    menu_safety: 'セキュリティ',
    menu_company: '会社概要',
    menu_donation: '寄付',

    // Cookie 同意
    cookie_text: 'より良い体験のために Cookie を使用しています。',
    cookie_policy_link: 'Cookie ポリシーを見る',
    accept_button: 'すべて受け入れる',
    reject_button: '不要なものを拒否する',

    // 機能セクション
    feature_no_subscription: 'サブスクリプション不要',
    feature_payg: '利用ごとの支払い（初回 10 ドルのチャージ）',
    feature_avatars: '12種類の個別アバター',
    feature_image_gen: '画像生成',
    feature_news: 'すべてのアバターが最新ニュースにアクセス可能',
    feature_speech: '音声出力と認識',
    feature_learning: 'すべてのアバターがチャットを通じて学習・適応'
  },
  'ar-SA': {
    welcome: 'مرحبًا بك في MyPal',
    login: 'تسجيل الدخول بالبريد الإلكتروني',
    register: 'تسجيل',
    backToLogin: 'العودة إلى تسجيل الدخول',
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    password: 'كلمة المرور',
    confirmPassword: 'تأكيد كلمة المرور',
    passwordsDoNotMatch: 'كلمتا المرور غير متطابقتين',
    invalidEmail: 'يرجى إدخال عنوان بريد إلكتروني صالح',
    invalidName: 'يرجى إدخال الاسم الأول واسم العائلة',
    wrongPassword: 'كلمة المرور غير صحيحة. المحاولات المتبقية: ',
    blocked: 'عدد المحاولات كبير جدًا. التطبيق مغلق.',
    startTrial: 'ابدأ تجربتك المجانية لمدة 14 يومًا',
    submitTrial: 'ابدأ التجربة',
    trialStarted: 'لقد بدأت فترة التجربة المجانية لمدة 14 يومًا. استمتع بالتطبيق!',
    loginSuccessful: 'تم تسجيل الدخول بنجاح!',
    registrationSuccessful: 'تم التسجيل بنجاح!',
    missingFields: 'البريد الإلكتروني وكلمة المرور مطلوبان',

    // القائمة
    menu_home: 'الرئيسية',
    menu_research: 'البحث',
    menu_products: 'المنتجات',
    menu_safety: 'الأمان',
    menu_company: 'الشركة',
    menu_donation: 'التبرع',

    // موافقة ملفات تعريف الارتباط
    cookie_text: 'نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك.',
    cookie_policy_link: 'عرض سياسة ملفات تعريف الارتباط',
    accept_button: 'قبول الكل',
    reject_button: 'رفض غير الضرورية',

    // قسم الميزات
    feature_no_subscription: 'بدون اشتراك',
    feature_payg: 'الدفع حسب الاستخدام مع تعبئة أولية بقيمة 10 دولارات',
    feature_avatars: '12 شخصية افتراضية فردية',
    feature_image_gen: 'إنشاء الصور',
    feature_news: 'جميع الشخصيات الافتراضية يمكنها الوصول إلى آخر الأخبار',
    feature_speech: 'إخراج الصوت والتعرف عليه',
    feature_learning: 'جميع الشخصيات الافتراضية تتعلم أثناء المحادثات وتتأقلم'
  },
};

export default translations;
